import React, { useState, useEffect } from 'react';
import { collection, getDoc, getDocs, doc, query, orderBy, where } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { waitForCurrentUser } from "../js_functions.js"
import { createBrowserHistory } from 'history';
import AddEditPlantType from './AddEditPlantType';
const history = createBrowserHistory();


const plant_types_page = {
  paddingTop: '30px',
  paddingLeft: '10px'
};

const tableContainerStyle = {
  maxWidth: '100%',
  overflowX: 'auto',
  maxHeight: '500px', // Adjust the max height as needed
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const tableCell = {
  border: '1px solid grey',
  padding: '8px',
};

const PlantTypes = () => {
  const [plantTypes, setPlantTypes] = useState([]);
  const [sortField, setSortField] = useState('plantTypeName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isArchivedFilterEnabled, setIsArchivedFilterEnabled] = useState(false); // State for the checkbox
  const [selectedPlantType, setSelectedPlantType] = useState('');



  useEffect(() => {
    loadPlantTypes();
  }, [sortField, sortOrder, isArchivedFilterEnabled, selectedPlantType]);

  const loadPlantTypes = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const plantTypesCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "plantTypes");

      let plantTypesQuery = query(plantTypesCollection);

      // Add filter for isArchived
      if (!isArchivedFilterEnabled) {
        const isArchivedFilter = where("isArchived", "==", false);
        plantTypesQuery = query(plantTypesQuery, isArchivedFilter);
      }
      // Add filter for plant type name
      if (selectedPlantType !== '') {
        const plantTypeFilter = where('plantTypeName', '==', selectedPlantType);
        plantTypesQuery = query(plantTypesQuery, plantTypeFilter);
      }
      const plantTypesSnapshot = await getDocs(plantTypesQuery);
      const plantTypesList = plantTypesSnapshot.docs.map(doc => ({ docID: doc.id, ...doc.data() }));

      // Fetch average actual yield for each plant type
      const plantTypesWithActualYield = await Promise.all(plantTypesList.map(async (plantType) => {
        const traysQuery = query(
          collection(getFirestoreDB(), "system", userDoc.data().system, "trays"),
          where("trayPlantTypeDocID", "==", plantType.docID),
          where("historicalData", "==", true)
        );
        const traysSnapshot = await getDocs(traysQuery);
        const actualYields = traysSnapshot.docs.map(doc => parseFloat(doc.data().actualYield) || 0); // Convert to numbers
        return {
          ...plantType,
          actualYield: actualYields.length > 0 ? actualYields.reduce((a, b) => a + b) / actualYields.length : 0
        };

      }));
      // Filter records without the display attribute or with display set to false
      const filteredPlantTypesList = plantTypesWithActualYield.filter(plantType => {
        const hasDisplay = typeof plantType.display !== "undefined" && plantType.display;
        return hasDisplay || typeof plantType.display === "undefined";
      });

      // Sort the filteredPlantTypesList based on the selected field and order
      filteredPlantTypesList.sort((a, b) => {
        const valueA = String(a[sortField]);
        const valueB = String(b[sortField]);

        if (sortOrder === 'asc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });

      setPlantTypes(filteredPlantTypesList);
    } catch (error) {
      console.log(error);
    }
  };




  const handleAddClick = () => {
    history.push("/add_edit_plantTypes");
    window.location.reload();
  };

  const handleEditClick = (docID) => {
    history.push(`/add_edit_plantTypes/${docID}`);
    window.location.reload();
  };

  const handleSortChange = (field) => {
    let newSortOrder = 'desc';

    if (field === 'plantTypeName')
      newSortOrder = 'asc';

    if (field === sortField) {
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  const handleIsArchivedFilterChange = () => {
    setIsArchivedFilterEnabled(!isArchivedFilterEnabled);
  };
  const handlePlantTypeChange = (event) => {
    setSelectedPlantType(event.target.value);
  };
  return (
    <div style={plant_types_page}>
      <div className="title is-5">Plant Types</div>
      <button className="button" onClick={handleAddClick}>Add Plant Type</button>
      <br />
      <br />
      <div>
        <label>
          <input type="checkbox" checked={isArchivedFilterEnabled} onChange={handleIsArchivedFilterChange} />
          Include Archived Records
        </label>
      </div>
      <div>
        <label>Filter by Plant Type:</label>
        <select value={selectedPlantType} onChange={handlePlantTypeChange}>
          <option value="">All</option>
          {plantTypes.map((plantType) => (
            <option key={plantType.docID} value={plantType.plantTypeName}>
              {plantType.plantTypeName}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div style={tableContainerStyle}>
        <table className="table" style={tableStyle}>
          <thead>
            <tr>
              <th style={tableCell} onClick={() => handleSortChange('plantTypeName')}>
                Plant Type {sortField === 'plantTypeName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={tableCell} onClick={() => handleSortChange('variation')}>
                Variation {sortField === 'variation' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={tableCell} onClick={() => handleSortChange('harvestSeedAmount')}>
                Seed Amount (lbs) {sortField === 'harvestSeedAmount' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={tableCell} onClick={() => handleSortChange('projectedYield')}>
                Projected Yield (lbs) {sortField === 'projectedYield' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={tableCell} onClick={() => handleSortChange('actualYield')}>
                Actual Yield (lbs) {sortField === 'actualYield' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={tableCell} onClick={() => handleSortChange('daysInGermination')}>
                Germination (days) {sortField === 'daysInGermination' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={tableCell} onClick={() => handleSortChange('daysInVegetation')}>
                Vegetation (days) {sortField === 'daysInVegetation' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
          <tbody>
            {plantTypes.map(plantType => (
              <tr key={plantType.docID} onClick={() => handleEditClick(plantType.docID)} style={{ cursor: 'pointer' }}>
                <td style={tableCell}>{plantType.plantTypeName}</td>
                <td style={tableCell}>{plantType.variation}</td>
                <td style={tableCell}>{plantType.harvestSeedAmount}</td>
                <td style={tableCell}>{plantType.projectedYield}</td>
                <td style={tableCell}>{plantType.actualYield}</td>
                <td style={tableCell}>{plantType.daysInGermination}</td>
                <td style={tableCell}>{plantType.daysInVegetation}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


export default PlantTypes;
