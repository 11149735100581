import React, { useState, useEffect } from 'react';
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { deleteDoc, collection, where, getDocs, getDoc, doc, addDoc, updateDoc, setDoc, Timestamp, query, orderBy } from "firebase/firestore";
import { waitForCurrentUser } from "../js_functions.js"
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const data_display_grid = {
  display: 'grid',
  gridTemplateColumns: '1.9in 1fr',
  width: '4in'
}
const data_edit_grid = {
  display: 'grid',
  gridTemplateColumns: '1.9in 1fr',
  width: '3.8in'
}
const data_display_grid_data = {
  textAlign: 'left'
}
const harvest_page = {
  paddingTop: '0.3in',
  paddingLeft: '0.3in'
}
const scrollableContainer = {
  display: 'flex',
  overflowX: 'auto',
  gap: '20px' /* Optional, adds space between cards */
}
const cardStyle = {
  flex: '0 0 auto'
}
const exceptThisSymbols = ["e", "E", "+", "-"];
const Harvest = () => {
  const [harvestName, setharvestName] = useState('');
  const [status, setstatus] = useState('');
  const [statusOptions, setstatusOptions] = useState({
    1: {displayName: 'Sow Ready',valueName: 'sow_ready'},
    2: {displayName: 'Germination',valueName: 'germination'},
    3: {displayName: 'Vegetative',valueName: 'vegetative'},
    4: {displayName: 'Harvest Ready',valueName: 'harvest_ready'},
    5: {displayName: 'Harvest Complete',valueName: 'harvest_complete'},
  });
  const [harvestPlantType, setharvestPlantType] = useState('');
  const [harvestPlantTypeVariation, setharvestPlantTypeVariation] = useState('');
  const [harvestSeedAmount, setharvestSeedAmount] = useState('');
  const [projectedYield, setprojectedYield] = useState('');
  const [projectedYieldTotal, setprojectedYieldTotal] = useState('');
  const [rot, setrot] = useState(0);
  const [sowDate, setsowDate] = useState(null);
  const [vegetationStartDate, setvegetationStartDate] = useState(null);
  const [projectedHarvestDate, setprojectedHarvestDate] = useState(null);
  const [actualYield, setactualYield] = useState(0);
  const [editMode, seteditMode] = useState('');
  const [historicalData, setHistoricalData] = useState(false);
  const [numberOfTrays, setnumberOfTrays] = useState('');
  const [trays, setTrays] = useState([]);

  useEffect(() => {
    loadHarvest()
  }, []);

  const getPlantTypeDoc = async (plantTypeDocID, userSystem) => {
    const plantTypeDocRef = doc(getFirestoreDB(), `system/${userSystem}/plantTypes`, plantTypeDocID);
    const plantTypeDoc = await getDoc(plantTypeDocRef);
    return plantTypeDoc.data();
  };

  const loadHarvest = async () => {
    try {
      let currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID)
      const userDoc = await getDoc(userDocRef)
      const queryString = window.location.search;
      const harvestDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/harvests', queryString.slice(4))
      const harvestDoc = await getDoc(harvestDocRef)
      const harvestDocData = harvestDoc.data()
      setharvestName(harvestDocData.harvestName)
      setstatus(harvestDocData.status)
      setharvestPlantType(harvestDocData.harvestPlantType)
      setharvestPlantTypeVariation(harvestDocData.harvestPlantTypeVariation)
      setharvestSeedAmount(harvestDocData.harvestSeedAmount)
      setprojectedYield(harvestDocData.projectedYield)
      setprojectedYieldTotal(harvestDocData.projectedYieldTotal)
      setactualYield(harvestDocData.actualYield)
      setrot(harvestDocData.rot)
      setsowDate(harvestDocData.sowDate)
      setvegetationStartDate(harvestDocData.vegetationStartDate)
      setprojectedHarvestDate(harvestDocData.projectedHarvestDate)
      setHistoricalData(harvestDocData.historicalData)
      setnumberOfTrays(harvestDocData.numberOfTrays || 0); // if trays is undefined, set it as an empty string
      const firestoreDB = getFirestoreDB();
      const traysRef = collection(firestoreDB, `system/${userDoc.data().system}/trays`);
      const trayQuery = query(traysRef, where("harvestId", "==", queryString.slice(4)), orderBy("customID")); // use harvestDocData.harvestId
      const querySnapshot = await getDocs(trayQuery);

      const traysData = [];

      for (const doc of querySnapshot.docs) {
        let tray = doc.data();

        let plantTypeDoc;
        if (tray.originalPlantType) {
          // use harvestDocData.harvestPlantTypeDocID
          plantTypeDoc = await getPlantTypeDoc(harvestDocData.harvestPlantTypeDocID, userDoc.data().system);
        } else {
          // use tray.trayPlantTypeDocID
          plantTypeDoc = await getPlantTypeDoc(tray.trayPlantTypeDocID, userDoc.data().system);
        }

        traysData.push({
          id: doc.id,
          ...tray,
          plantTypeDoc: plantTypeDoc,
        });
      }

      setTrays(traysData);
    } catch(error) {
      console.log(error)
    }
  }
  const handleEditClick = () => {
    seteditMode(true)
  };
  const handleBackClick = () => {
    history.back();
  };
  const handleSaveClick = async (inputName) => {
    if (!harvestName) {
      alert('Please enter a harvest name.');
      return;
    }
    try {
      let currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID)
      const userDoc = await getDoc(userDocRef)
      const queryString = window.location.search;
      const harvestDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/harvests', queryString.slice(4))
      await setDoc(harvestDocRef, {
        harvestName: harvestName,
        status: status,
        harvestPlantType: harvestPlantType,
        harvestPlantTypeVariation: harvestPlantTypeVariation,
        harvestSeedAmount: harvestSeedAmount,
        projectedYield: projectedYield,
        projectedYieldTotal: projectedYieldTotal,
        actualYield: actualYield ? actualYield : 0,
        rot: rot ? rot : 0,
        sowDate: sowDate ? sowDate : null,
        vegetationStartDate: vegetationStartDate ? vegetationStartDate : null,
        projectedHarvestDate: projectedHarvestDate ? projectedHarvestDate : null,
        numberOfTrays: numberOfTrays ? numberOfTrays : 0
      }, { merge: true });

      for (let tray of trays) {
        const trayData = {
          customID: tray.customID,
          substrate: tray.substrate,
          traySize: tray.traySize,
          originalPlantType: tray.originalPlantType,
          plantTypeDoc: tray.plantTypeDoc,
          sowDate: tray.sowDate,
          vegetationStartDate: tray.vegetationStartDate,
          projectedHarvestDate: tray.projectedHarvestDate,
          trayPlantTypeDocID: tray.trayPlantTypeDocID,
          status: status,
          // ... rest of the tray data
        };

        if (tray.actualYield)
          trayData.actualYield = parseFloat(tray.actualYield)
        if (tray.rot)
          trayData.rot = parseFloat(tray.rot)


        const traysCollection = collection(getFirestoreDB(), "system", userDoc.data().system, 'trays');
        const trayDoc = doc(traysCollection, tray.id); // you need to provide id of document you want to update
        const traySnapshot = await getDoc(trayDoc);

        if (traySnapshot.exists) { // check if document exists before updating
          await updateDoc(trayDoc, trayData);
        } else {
          console.error(`Document with id ${tray.customID} doesn't exist.`);
        }
      }

    } catch(error) {
      console.log(error)
    }
    seteditMode(false)
  };

  const handleCancelClick = () => {
    seteditMode(false)
    window.location.reload()
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;

    switch (event.target.name) {
      case 'harvestName':
        setharvestName(value)
        break;
      case 'status':
        setstatus(value)
        break;
      default:
        break;
    }
  };
  const handleTrayChange = (index, key, value) => {
  let newTrays = [...trays];
  if (key === 'vegetationStartDate' || key === 'sowDate' || key === 'projectedHarvestDate') {
    // Convert date strings to Timestamp objects for vegetationStartDate, sowDate, and projectedHarvestDate
    const date = Timestamp.fromDate(new Date(value + "T00:00:00"))
    value = date;
  } else if (key === 'actualYield' || key === 'rot') {
    // Input validation for "Actual Yield" and "Rot"
    const validFormat = /^[0-9]*\.?[0-9]*$/;

    if (validFormat.test(value) || value === '') {
      // If the input matches the desired format or is empty, update the value
      // value = value === '' ? '' : parseFloat(value);
    } else {
      // If it doesn't match, you can handle it accordingly
      // For example, you can set an error state to display an error message to the user.
      // setErrorState(true);
      return; // Exit the function to prevent updating the state with an invalid value
    }
  }
  newTrays[index][key] = value;
  setTrays(newTrays);
};




  const handleCreateNewPlantType = async (index) => {

    let newTrays = [...trays];

    if (newTrays[index].originalPlantType === 1){

      const variationName = window.prompt("Enter Variation Name:", "DefaultVariation");
      if (!variationName) {
        return; // User didn't provide a variation name
      }

      const tray = trays[index];

      // Calculate days in germination and days in vegetation
      const germinationStartDate = tray.sowDate.toDate();
      const vegetationStartDate = tray.vegetationStartDate.toDate();
      vegetationStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
      const daysInGermination = Math.floor((vegetationStartDate - germinationStartDate) / (1000 * 60 * 60 * 24));
      const daysInVegetation = Math.floor((tray.projectedHarvestDate.toDate() - vegetationStartDate) / (1000 * 60 * 60 * 24));

      try {
        const newPlantType = {
          plantTypeName: tray.plantTypeDoc.plantTypeName,
          variation: variationName,
          harvestSeedAmount: tray.harvestSeedAmount,
          projectedYield: tray.plantTypeDoc.projectedYield,
          daysInGermination,
          daysInVegetation,
          defaultTraySubstrate: tray.substrate,
          defaultTraySize: tray.traySize,
          isArchived: false,
          display: true,
        };
        let userID = await getFirestoreAuth().currentUser.uid;
        const userDocRef = doc(getFirestoreDB(), "users", userID);
        const userDoc = await getDoc(userDocRef);
        const plantTypesCollection = collection(
          getFirestoreDB(),
          "system",
          userDoc.data().system,
          "plantTypes"
        );

        const newPlantTypeDocRef = doc(plantTypesCollection);
        await setDoc(newPlantTypeDocRef, newPlantType);

        let newPlantTypeDocID = newPlantTypeDocRef.id;
        tray.trayPlantTypeDocID = newPlantTypeDocID;
        tray.originalPlantType = 0;
        tray.plantTypeDoc = newPlantType;

        // Save the tray data with the updated plant type
        const traysCollection = collection(getFirestoreDB(), "system", userDoc.data().system, 'trays');
        const trayDoc = doc(traysCollection, tray.id);
        await updateDoc(trayDoc, tray);
      } catch (error) {
        console.log(error);
      }



    }else{
      const shouldEditNewPlanType = window.confirm("Do you want to edit the linked planType based on this change?");
      if (shouldEditNewPlanType) {
        let userID = await getFirestoreAuth().currentUser.uid;
        const userDocRef = doc(getFirestoreDB(), "users", userID);

        const germinationStartDate = newTrays[index].sowDate.toDate();
        const vegetationStartDate = newTrays[index].vegetationStartDate.toDate();
        vegetationStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
        const daysInGermination = Math.floor((vegetationStartDate - germinationStartDate) / (1000 * 60 * 60 * 24));
        const daysInVegetation = Math.floor((newTrays[index].projectedHarvestDate.toDate() - vegetationStartDate) / (1000 * 60 * 60 * 24));

        const exsistingPlantType = {
          defaultTraySubstrate: newTrays[index].substrate ? newTrays[index].substrate : "",
          defaultTraySize: newTrays[index].traySize ? newTrays[index].traySize : "",
          daysInGermination,
          daysInVegetation
        };
        try {
          const userDoc = await getDoc(userDocRef);
          const plantTypeDocRef = doc(
            getFirestoreDB(),
            "system",
            userDoc.data().system,
            "plantTypes",
            newTrays[index].trayPlantTypeDocID
          );
          await updateDoc(plantTypeDocRef, exsistingPlantType);
        } catch (error) {
          console.log(error);
        }
      }
    }
    setTrays(newTrays);
    handleSaveClick()
  };

  const deleteHarvestClick = async (event) => {
    event.preventDefault();
    try {
      let userID = await getFirestoreAuth().currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID)
      const userDoc = await getDoc(userDocRef)
      const queryString = window.location.search;
      const harvestDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/harvests', queryString.slice(4))

      await updateDoc(harvestDocRef, {
        display: false // Set the "display" attribute to false
      });

      history.push("/harvests");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const flagHistoricalData = async (event) => {
    event.preventDefault();
    try {
      let userID = await getFirestoreAuth().currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID)
      const userDoc = await getDoc(userDocRef)
      const queryString = window.location.search;
      const harvestDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/harvests', queryString.slice(4));

      // Toggle the historicalData value
      const updatedHistoricalData = !historicalData;

      // Check if the document has the historicalData field
      const harvestDoc = await getDoc(harvestDocRef);
      const harvestDocData = harvestDoc.data();
      const hasHistoricalDataField = harvestDocData.hasOwnProperty("historicalData");

      // Update the state
      setHistoricalData(updatedHistoricalData);

      // Update the database
      if (hasHistoricalDataField) {
        await updateDoc(harvestDocRef, { historicalData: updatedHistoricalData });
      } else {
        await setDoc(harvestDocRef, {
          historicalData: updatedHistoricalData,
          numberOfTrays: numberOfTrays,
         }, { merge: true });
      }


      // Fetch all trays associated with the current harvest
      // const traysCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "trays");
      // const traysQuery = query(traysCollection, where("harvestID", "==", queryString.slice(4)));


      const traysRef = collection(getFirestoreDB(), `system/${userDoc.data().system}/trays`);
      const traysQuery = query(traysRef, where("harvestId", "==", queryString.slice(4)), orderBy("customID")); // use harvestDocData.harvestId
      const traysSnapshot = await getDocs(traysQuery);

      // Update the historicalData field for each tray
      traysSnapshot.docs.forEach(async (trayDoc) => {
        const trayRef = trayDoc.ref;

        // Update the database
        if (hasHistoricalDataField) {
          await updateDoc(trayRef, { historicalData: updatedHistoricalData });
        } else {
          await setDoc(trayRef, {
            historicalData: updatedHistoricalData,
          }, { merge: true });
        }

      });


    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={harvest_page}>
      {harvestName ? (
        <div>
          {editMode ? (
            <div>
              <div style={{display:'grid', gridTemplateColumns: '0.8in 1.5in 1in', gridColumnGap: '0.18in'}}>
                <div className="title is-5 is-success">Harvest Page</div>
                <button className="button is-success" onClick={handleSaveClick}>Save Harvest/Trays</button>
                <button className="button" onClick={handleCancelClick}>Cancel</button>
              </div>

              <br></br>
              <br></br>
              <div style={data_edit_grid}>
                <div>Harvest Name:</div>
                <div><input className="input" type="text" name="harvestName" value={harvestName} onChange={handleInputChange}/></div>
                <div>Status:</div>
                <div className="select">
                <select className="select" name="status" value={status} onChange={handleInputChange}>
                  {Object.entries(statusOptions).map(([key, option]) => (
                    <option key={key} value={option.valueName}>
                      {option.displayName}
                    </option>
                  ))}
                </select>
                </div>
                <div>Plant Type:</div>
                <div style={data_display_grid_data}>{harvestPlantType}</div>
                <div>Plant Type Variation:</div>
                <div style={data_display_grid_data}>{harvestPlantTypeVariation}</div>
                <div>Seed per Tray:</div>
                <div style={data_display_grid_data}>{harvestSeedAmount} lbs</div>
                <div>Projected Yield per Tray:</div>
                <div style={data_display_grid_data}>{projectedYield} lbs</div>
                <div>Projected Yield Total:</div>
                <div style={data_display_grid_data}>{projectedYieldTotal} lbs</div>
                <div>Sow Date:</div>
                <div style={data_display_grid_data}>  {(sowDate && sowDate.toDate().toLocaleDateString('en-US'))}</div>
                <div>Vegetation Date:</div>
                <div style={data_display_grid_data}>  {(vegetationStartDate && vegetationStartDate.toDate().toLocaleDateString('en-US'))}</div>
                <div>Projected Harvest Date:</div>
                <div style={data_display_grid_data}>  {(projectedHarvestDate && projectedHarvestDate.toDate().toLocaleDateString('en-US'))}</div>
                <div>Number of Trays:</div>
                <div style={data_display_grid_data}>{numberOfTrays}</div>
              </div>
              <br />
              {status === 'harvest_complete' && (
              <div>
                {/* <div>Post-Harvest Data</div>
                <div style={data_edit_grid}>
                  <div>Actual Yield (lbs):</div>
                  <div><input type="number" name="actualYield" value={actualYield} onChange={handleInputChange}/></div>
                  <div>Rot:</div>
                  <div><input type="number" name="rot" value={rot} onChange={handleInputChange}/></div>
                </div> */}
              </div>
              )}
              <div style={scrollableContainer}>
                {trays.map((tray, index) => (
                  <div key={index} className="card" style={cardStyle}>
                    <nav className="card-header" style={{backgroundColor: 'rgb(52, 235, 143)'}}>
                      <p className="card-header-title">
                        Tray
                        <input
                          className="input"
                          type="text"
                          value={tray.customID}
                          onChange={e => handleTrayChange(index, 'customID', e.target.value)}
                        />
                      </p>
                    </nav>
                    <div className="card-content">
                      <div className="field">
                        <label className="label">PlantType - Variation:</label>
                        <div className="control">
                          <input
                            disabled
                            className="input"
                            type="text"
                            value={tray.plantTypeDoc.plantTypeName + ' - ' + tray.plantTypeDoc.variation}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Substrate:</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            value={tray.substrate}
                            onChange={e => handleTrayChange(index, 'substrate', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Tray Size:</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            value={tray.traySize}
                            onChange={e => handleTrayChange(index, 'traySize', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Sow Date:</label>
                        <div className="control">
                          <input
                            className="input"
                            type="date"
                            value={tray.sowDate?.toDate().toISOString().substr(0, 10)}
                            onChange={e => handleTrayChange(index, 'sowDate', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Vegetation Start Date:</label>
                        <div className="control">
                          <input
                            className="input"
                            type="date"
                            value={tray.vegetationStartDate?.toDate().toISOString().substr(0, 10)}
                            onChange={e => handleTrayChange(index, 'vegetationStartDate', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Projected Harvest Date:</label>
                        <div className="control">
                          <input
                            className="input"
                            type="date"
                            value={tray.projectedHarvestDate?.toDate().toISOString().substr(0, 10)}
                            onChange={e => handleTrayChange(index, 'projectedHarvestDate', e.target.value)}
                          />
                        </div>
                      </div>
                      {status === 'harvest_complete' && (
                      <div>
                        <div>Post-Harvest Data</div>
                        <div className="field">
                          <label className="label">Actual Yield (lbs):</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              inputMode="decimal"
                              pattern="^[0-9]*\.?[0-9]*$"
                              value={tray.actualYield ? tray.actualYield : ''}
                              onChange={e => handleTrayChange(index, 'actualYield', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Rot:</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              inputMode="decimal"
                              pattern="^[0-9]*\.?[0-9]*$"
                              value={tray.rot ? tray.rot : ''}
                              onChange={e => handleTrayChange(index, 'rot', e.target.value)}
                              />
                          </div>
                        </div>
                      </div>
                      )}
                      {tray.originalPlantType === 1 && (
                        <div>
                          <br></br>
                          <button className="button is-primary" onClick={() => handleCreateNewPlantType(index)}>
                            Create New PlantType
                          </button>
                        </div>
                      )}
                      {tray.originalPlantType === 0 && (
                        <div>
                          <br></br>
                          <button className="button is-primary" onClick={() => handleCreateNewPlantType(index)}>
                            Save PlantType
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <br></br>
              <br></br>
            </div>
          ) : (
            <div>
              <div style={{ display: 'grid', gridTemplateColumns: '1.1in 1.1in', gridColumnGap: '0.25in' }}>
                <button className="button" onClick={handleBackClick}>Back</button>
                <button className="button" onClick={handleEditClick}>Edit</button>
              </div>
              <br></br>
              <br></br>
              <div style={data_display_grid}>
                <div>Harvest Name:</div>
                <div style={data_display_grid_data}>{harvestName}</div>
                <div>Status:</div>
                <div style={data_display_grid_data}>{status}</div>
                <div>Plant Type:</div>
                <div style={data_display_grid_data}>{harvestPlantType}</div>
                <div>Plant Type Variation:</div>
                <div style={data_display_grid_data}>{harvestPlantTypeVariation}</div>
                <div>Seed per Tray:</div>
                <div style={data_display_grid_data}>{harvestSeedAmount} lbs</div>
                <div>Projected Yield per Tray:</div>
                <div style={data_display_grid_data}>{projectedYield} lbs</div>
                <div>Projected Yield Total:</div>
                <div style={data_display_grid_data}>{projectedYieldTotal} lbs</div>
                <div>Sow Date:</div>
                <div style={data_display_grid_data}>{sowDate && sowDate.toDate().toLocaleDateString()}</div>
                <div>Vegetation Start Date:</div>
                <div style={data_display_grid_data}>{vegetationStartDate && vegetationStartDate.toDate().toLocaleDateString()}</div>
                <div>Projected Harvest Date:</div>
                <div style={data_display_grid_data}>{projectedHarvestDate && projectedHarvestDate.toDate().toLocaleDateString()}</div>
                <div>Number of Trays:</div>
                <div style={data_display_grid_data}>{numberOfTrays}</div>
              </div>
              <br />
              <br />
                {status === 'harvest_complete' && (
                <div>
                  {/* <div>Post-Harvest Data</div>
                  <div style={data_display_grid}>
                    <div>Actual Yield (lbs):</div>
                    <div style={data_display_grid_data}>{actualYield}</div>
                    <div>Rot:</div>
                    <div style={data_display_grid_data}>{rot}</div>
                  </div> */}
                  <br />
                  <button className="button" onClick={flagHistoricalData}>
                    {historicalData ? "Unflag Historical Data" : "Flag for Historical Data"}
                  </button>
                </div>
                )}
              <br></br>


              <div style={scrollableContainer}>
                {trays.map((tray, index) => (
                  <div key={index} className="card" style={cardStyle}>
                    <nav className="card-header" style={{backgroundColor: 'rgb(52, 235, 143)'}}>
                      <p className="card-header-title">
                        Tray {tray.customID}
                      </p>
                    </nav>
                    <div className="card-content">
                      <div className="field">
                        <label className="label">Substrate:</label>
                        <div className="control">
                          {tray.substrate}
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Tray Size:</label>
                        <div className="control">
                          {tray.traySize}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <button className="button is-danger" onClick={deleteHarvestClick}>Delete</button>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default Harvest;
