import React, { Component } from "react";
import { Link } from 'react-router-dom';

import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { collection, query, where, getDocs, getDoc, doc, orderBy, Timestamp } from "firebase/firestore";
import { createBrowserHistory } from 'history';
import { waitForCurrentUser } from "../js_functions.js"

const history = createBrowserHistory();

const home_box = {
  // paddingLeft: '10px',
}

const cardStyles = {
  margin: '10px',
  borderRadius: '5px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
};

const cardContentStyles = {
  padding: '20px',
  textAlign: 'left',
};

const cardButtonStyles = {
  marginTop: '10px',
  display: 'block',
  width: '1.2in',
  marginRight: '0.2in'
};
const cardGridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '20px',
};

const cardColumnStyles = {
  display: 'block', // Display cards as a single column on small screens
  margin: '0 auto', // Center align cards
};
export default class UserHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderCounts: {
        open: 0,
        readyToInvoice: 0,
        invoiceSent: 0,
        paymentReceived: 0,
        closedOut: 0,
      },
    };
  }

  async componentDidMount() {
    if (!this.props.isLoggedIn) {
      history.push("/home");
      window.location.reload();
    } else {
      try {
        let currentUser = await waitForCurrentUser();
        let userID = await currentUser.uid;
        const userDocRef = doc(getFirestoreDB(), "users", userID);
        const userDoc = await getDoc(userDocRef);
        const harvestsRef = collection(getFirestoreDB(), "system", userDoc.data().system, 'harvests');
        // Fetch the harvests data from Firestore and calculate incompleteHarvestCount
        const harvestsSnapshot = await getDocs(harvestsRef);
        const incompleteHarvests = harvestsSnapshot.docs.filter(harvestDoc => {
          const data = harvestDoc.data();
          return data.status !== 'harvest_complete' && data.display === true;
        });

        const ordersRef = collection(getFirestoreDB(), "system", userDoc.data().system, 'orders');
        const ordersSnapshot = await getDocs(ordersRef);

        // Calculate order counts for each status
        const orderCounts = {
          open: 0,
          ready_to_invoice: 0,
          invoice_sent: 0,
          payment_received: 0,
          closed_out: 0,
        };

        ordersSnapshot.docs.forEach(orderDoc => {
          const orderStatus = orderDoc.data().status;
          const display = orderDoc.data().display;

          // Check if display is not false, null, or undefined and if the order status is valid
          if (display !== false && display !== null && display !== undefined) {
            orderCounts[orderStatus]++;
          }
        });

        // Fetch the customers data from Firestore and calculate the customer count
        const customersRef = collection(getFirestoreDB(), "system", userDoc.data().system, 'customers');
        const customersSnapshot = await getDocs(customersRef);
        const filteredCustomers = customersSnapshot.docs
          .filter(customerDoc => customerDoc.data().display === true); // Filter customers with display == true
        const customerCount = filteredCustomers.length; // Calculate the customer count


        // Fetch the plant types data from Firestore and calculate the plant type count
        const plantTypesRef = collection(getFirestoreDB(), "system", userDoc.data().system, 'plantTypes');
        const plantTypesSnapshot = await getDocs(plantTypesRef);

        // Filter plant types where display is true
        const filteredPlantTypes = plantTypesSnapshot.docs.filter(plantTypeDoc => {
          const data = plantTypeDoc.data();
          return data.display === true;
        });

        // Calculate the plant type count based on the filtered documents
        const plantTypeCount = filteredPlantTypes.length;
        this.setState({
          incompleteHarvestCount: incompleteHarvests.length ? incompleteHarvests.length : 0,
          orderCounts: orderCounts,
          customerCount: customerCount,
          plantTypeCount: plantTypeCount,
        });

      } catch (error) {
        console.error("Error fetching harvest data:", error);
      }
    }
  }
  render() {
    return (
      <div style={home_box}>
        <br />
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              {/* Hero Section */}
              <h1 className="title">The Sprout Report</h1>
              <p className="subtitle">Dial In Your Harvest Process.</p>
              {!this.props.isLoggedIn && (
                <div className="buttons">
                  <a href="/signup" className="button is-primary is-rounded">Sign Up</a>
                  <a href="/login" className="button is-info is-rounded">Sign In</a>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 className="title is-3" style={{ marginRight: '40px' }}>Workflow Steps</h2>
              <Link to={"/calendar"} className="navbar-item" style={{
                  backgroundColor: 'teal',
                  marginRight: '30px',
                  marginTop: '15px',
                  marginBottom: '15px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '8px',
                  color: 'white',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  transition: 'background-color 0.3s ease-in-out',
                }}>
                Calendar
              </Link>
            </div>
            <div className="columns" style={
              window.innerWidth > 750 // Adjust the breakpoint as needed
                ? cardGridStyles
                : cardColumnStyles
            }>
              <div className="column">
                <div style={cardStyles} className="card">
                  <div style={cardContentStyles} className="card-content">
                    <div className="title">PlantTypes</div>
                    <table className="table is-narrow is-fullwidth">
                      <tbody>
                        <tr>
                          <td className="has-text-weight-semibold">Number of Plant Types:</td>
                          <td>{this.state.plantTypeCount}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{display: 'flex'}}>
                      <button onClick={() => { history.push("/plantTypes"); window.location.reload(); }} style={cardButtonStyles} className="button is-link">Dashboard</button>
                      <button onClick={() => { history.push("/add_edit_plantTypes"); window.location.reload(); }} style={cardButtonStyles} className="button is-link">Create</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div style={cardStyles} className="card">
                  <div style={cardContentStyles} className="card-content">
                    <div className="title">Harvests</div>
                    <div >
                    <table className="table is-narrow is-fullwidth">
                      <tbody>
                        <tr>
                          <td className="has-text-weight-semibold">Open Harvests:</td>
                          <td>{this.state.incompleteHarvestCount}</td>
                        </tr>
                      </tbody>
                    </table>
                      <div style={{display: 'flex'}}>
                        <button
                          onClick={() => { history.push("/harvests"); window.location.reload(); }}
                          style={cardButtonStyles}
                          className="button is-link"
                        >
                          Dashboard
                        </button>
                        <button
                          onClick={() => { history.push("/add_harvest"); window.location.reload(); }}
                          style={cardButtonStyles}
                          className="button is-link"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div style={cardStyles} className="card">
                  <div style={cardContentStyles} className="card-content">
                    <div className="title">Orders</div>
                    <table className="table is-narrow is-fullwidth">
                      <tbody>
                        {/* Display order counts for each status */}
                        {Object.keys(this.state.orderCounts).map(status => (
                          <tr key={status}>
                            <td className="has-text-weight-semibold">{status}:</td>
                            <td>{this.state.orderCounts[status]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <button onClick={() => { history.push("/orders"); window.location.reload(); }} style={cardButtonStyles} className="button is-link">Dashboard</button>
                      <button onClick={() => { history.push("/add_edit_order"); window.location.reload(); }} style={cardButtonStyles} className="button is-link">Create</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div style={cardStyles} className="card">
                  <div style={cardContentStyles} className="card-content">
                    <div className="title">Customers</div>
                    <table className="table is-narrow is-fullwidth">
                      <tbody>
                        <tr>
                          <td className="has-text-weight-semibold">Number of Customers:</td>
                          <td>{this.state.customerCount}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{display: 'flex'}}>
                      <button onClick={() => { history.push("/customers"); window.location.reload(); }} style={cardButtonStyles} className="button is-link">Dashboard</button>
                      <button onClick={() => { history.push("/add_edit_customer"); window.location.reload(); }} style={cardButtonStyles} className="button is-link">Create</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <a href="/contact" className="button is-link">Contact Us</a>
        </section>
      </div>
    );
  }
}
