import '../Switch.css';
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { useParams } from 'react-router-dom';
import { waitForCurrentUser } from "../js_functions.js"
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const plantTypeFormStyle = {
  paddingTop: '30px',
  paddingLeft: '20px'
}

const AddEditPlantType = ({ isLoggedIn }) => {
  const { docID } = useParams(); // Add this line
  const [plantTypeName, setPlantTypeName] = useState('');
  const [variation, setVariation] = useState('');
  const [harvestSeedAmount, setHarvestSeedAmount] = useState(0.0);
  const [projectedYield, setProjectedYield] = useState(0.0);
  const [daysInGermination, setDaysInGermination] = useState(0);
  const [daysInVegetation, setDaysInVegetation] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isArchived, setIsArchived] = useState(false); // State for archived status
  const [plantTypeNames, setPlantTypeNames] = useState([]);
  const [selectedPlantType, setSelectedPlantType] = useState(null);
  const [defaultTraySubstrate, setDefaultTraySubstrate] = useState('');
  const [defaultTraySize, setDefaultTraySize] = useState('');




  useEffect(() => {
    if (docID) {
      setIsEditing(true);
      loadPlantType(docID);
    }
    fetchPlantTypeNames(); // Fetch plant type names
  }, [docID]);

  const loadPlantType = async (docID) => {
    try {
      let currentUser = await waitForCurrentUser();
      let userID = await currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const plantTypeDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/plantTypes', docID);
      const plantTypeDoc = await getDoc(plantTypeDocRef);

      if (plantTypeDoc.exists()) {
        setSelectedPlantType({
          id: plantTypeDoc.id,
          name: plantTypeDoc.data().plantTypeName,
          variation: plantTypeDoc.data().variation,
          harvestSeedAmount: plantTypeDoc.data().harvestSeedAmount,
          projectedYield: plantTypeDoc.data().projectedYield,
          daysInGermination: plantTypeDoc.data().daysInGermination,
          daysInVegetation: plantTypeDoc.data().daysInVegetation,
          isArchived: plantTypeDoc.data().isArchived,
          defaultTraySubstrate: plantTypeDoc.data().defaultTraySubstrate,
          defaultTraySize: plantTypeDoc.data().defaultTraySize

        });
        setPlantTypeName(plantTypeDoc.data().plantTypeName);
        setVariation(plantTypeDoc.data().variation);
        setHarvestSeedAmount(plantTypeDoc.data().harvestSeedAmount);
        setProjectedYield(plantTypeDoc.data().projectedYield);
        setDaysInGermination(plantTypeDoc.data().daysInGermination);
        setDaysInVegetation(plantTypeDoc.data().daysInVegetation);
        setIsArchived(plantTypeDoc.data().isArchived);
        setDefaultTraySubstrate(plantTypeDoc.data().defaultTraySubstrate);
        setDefaultTraySize(plantTypeDoc.data().defaultTraySize);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPlantTypeNames = async () => {
    try {
      let currentUser = await waitForCurrentUser();
      let userID = await currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const plantTypesQuery = query(
        collection(getFirestoreDB(), "system", userDoc.data().system, "plantTypes"),
        where("isArchived", "==", false), // Only fetch unarchived plant types
        where("display", "==", true) // Only fetch documents where display is true
      );
      const plantTypesSnapshot = await getDocs(plantTypesQuery);
      const names = plantTypesSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().plantTypeName,
        variation: doc.data().variation,
        harvestSeedAmount: doc.data().harvestSeedAmount,
        projectedYield: doc.data().projectedYield,
        daysInGermination: doc.data().daysInGermination,
        daysInVegetation: doc.data().daysInVegetation,
        isArchived: doc.data().isArchived,
        defaultTraySubstrate: doc.data().defaultTraySubstrate,
        defaultTraySize: doc.data().defaultTraySize

      }));
      setPlantTypeNames(names);
    } catch (error) {
      console.log(error);
    }
  };


  const handleSubmit = async (event, saveNewVariation = false) => {
    event.preventDefault();

    const newPlantType = {
      plantTypeName: plantTypeName ? plantTypeName : "",
      variation: variation ? variation : "",
      harvestSeedAmount: parseFloat(harvestSeedAmount),
      projectedYield: parseFloat(projectedYield),
      daysInGermination: parseInt(daysInGermination),
      daysInVegetation: parseInt(daysInVegetation),
      isArchived: isArchived, // Include archived status in the new plantType object
      defaultTraySubstrate: defaultTraySubstrate ? defaultTraySubstrate : "",
      defaultTraySize: defaultTraySize ? defaultTraySize : "",
    };

    let userID = await getFirestoreAuth().currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID);

    try {
      const userDoc = await getDoc(userDocRef);
      const plantTypesCollection = collection(
        getFirestoreDB(),
        "system",
        userDoc.data().system,
        "plantTypes"
      );

      if (isEditing) {
        if (saveNewVariation) {
            const newPlantTypeDocRef = doc(plantTypesCollection);
            await setDoc(newPlantTypeDocRef, newPlantType);
            setIsEditing(false);
        } else {
          const plantTypeDocRef = doc(
            getFirestoreDB(),
            "system/" + userDoc.data().system + "/plantTypes",
            docID
          );
          await updateDoc(plantTypeDocRef, newPlantType);
        }
      } else {
        newPlantType.display = true;
        await setDoc(doc(plantTypesCollection), newPlantType);
      }
    } catch (error) {
      console.log(error);
    }

    history.push("/plantTypes");
    window.location.reload();
  };


  const handleDelete = async () => {
    try {
      let currentUser = await waitForCurrentUser();
      let userID = await currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);

      const plantTypeDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/plantTypes', docID);
      const plantTypeDoc = await getDoc(plantTypeDocRef);

      if (plantTypeDoc.exists()) {
        await updateDoc(plantTypeDocRef, {
          display: false // Set the "display" attribute to false
        });
      } else {
        await setDoc(plantTypeDocRef, {
          display: false // Create the document with the "display" attribute set to false
        });
      }
      history.push("/plantTypes");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlantTypeChange = (e) => {
    const selectedPlantTypeID = e.target.value;
    const selectedPlantType = plantTypeNames.find((plantType) => plantType.id === selectedPlantTypeID && !plantType.isArchived); // Only select unarchived plant types

    // Check if isEditing is true and a plant type is already selected
    if (isEditing && selectedPlantType) {
      return; // Return early to prevent updating the state
    }

    setSelectedPlantType(selectedPlantType);
    if (selectedPlantType) {
      setPlantTypeName(selectedPlantType.name);
      setVariation(selectedPlantType.variation);
      setHarvestSeedAmount(selectedPlantType.harvestSeedAmount);
      setProjectedYield(selectedPlantType.projectedYield);
      setDaysInGermination(selectedPlantType.daysInGermination);
      setDaysInVegetation(selectedPlantType.daysInVegetation);
      setIsArchived(selectedPlantType.isArchived);
    } else {
      setPlantTypeName('');
      setVariation('');
      setHarvestSeedAmount(0.0);
      setProjectedYield(0.0);
      setDaysInGermination(0);
      setDaysInVegetation(0);
      setIsArchived(false);
    }
  };

  const handleArchiveChange = (event) => {
    setIsArchived(event.target.checked); // Set the value of isArchived based on the checked state of the checkbox
  };
  const handleCancel = () => {
    history.back(); // Go back to the previous page
  };

  const handleHarvestSeedAmountChange = (e) => {
    const inputValue = e.target.value;

    // Define a regular expression for the desired format
    const validFormat = /^[0-9]*\.?[0-9]*$/;

    // Check if the input matches the desired format
    if (validFormat.test(inputValue)) {
      // If it matches, update the state
      setHarvestSeedAmount(inputValue);
    } else {
      // If it doesn't match, you can show an error message or handle it accordingly
      // For example, you can set an error state to display an error message to the user.
      // setErrorState(true);
    }
  };
  const handleProjectedYieldChange = (e) => {
    const inputValue = e.target.value;

    // Define a regular expression for the desired format
    const validFormat = /^[0-9]*\.?[0-9]*$/;

    // Check if the input matches the desired format
    if (validFormat.test(inputValue)) {
      // If it matches, update the state
      setProjectedYield(inputValue);
    } else {
      // If it doesn't match, you can handle it accordingly
      // For example, you can set an error state to display an error message to the user.
      // setErrorState(true);
    }
  };


  return (
    <div style={plantTypeFormStyle}>
      <div style={{display:'grid', gridTemplateColumns: '1in 1.6in 1.1in', gridColumnGap: '0.25in'}}>
      <div className="title is-5">{isEditing ? 'Edit Plant Type' : 'Add Plant Type'}</div>
      {isEditing && (
        <button className="button is-success" onClick={(event) => handleSubmit(event)}>Save Existing</button>
      )}
      <button style={{'width': '1.55in'}} className="button is-success" onClick={(event) => handleSubmit(event, true)}>Save New Variation</button>
      <button className="button" type="button" onClick={handleCancel}>Cancel</button>
    </div>
      <br />
      {!isEditing && (
        <div className="field">
          <label className="label">Load Plant Type:</label>
          <div className="control">
            <select className="select" value={selectedPlantType ? selectedPlantType.id : ''} onChange={handlePlantTypeChange}>
              <option value="">New</option>
              {plantTypeNames.map((plantType) => (
                <option key={plantType.id} value={plantType.id}>
                  {plantType.name} - {plantType.variation}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
      <div style={{ display: 'grid', gridTemplateColumns: '1.8in 2in', gridColumnGap: '10px' }}>
        <div>
          <div className="field">
            <label className="label">Plant Type Name:</label>
            <div className="control">
              <input className="input" value={plantTypeName} onChange={(e) => setPlantTypeName(e.target.value)} />
            </div>
          </div>
          <div className="field">
            <label className="label">Variation:</label>
            <div className="control">
              <input className="input" value={variation} onChange={(e) => setVariation(e.target.value)} />
            </div>
          </div>
          <div className="field">
            <label className="label">Seed Amount<br /> per tray (lbs):</label>
            <div className="control">

            <input
              className="input"
              type="text"
              inputMode="decimal"
              pattern="^[0-9]*\.?[0-9]*$"
              value={harvestSeedAmount}
              onChange={handleHarvestSeedAmountChange}
            />

            </div>
          </div>
          <div className="field">
            <label className="label">Projected Yield<br /> per tray (lbs):</label>
            <div className="control">
            <input
              className="input"
              type="text"
              inputMode="decimal"
              pattern="^[0-9]*\.?[0-9]*$"
              value={projectedYield}
              onChange={handleProjectedYieldChange}
            />
            </div>
          </div>
          <div className="field">
            <label className="label">Days in Germination:</label>
            <div className="control">
              <input
                className="input"
                type="number"
                inputMode="numeric"
                value={daysInGermination}
                onChange={(e) => setDaysInGermination(parseInt(e.target.value))} />
            </div>
          </div>
          <div className="field">
            <label className="label">Days in Vegetation:</label>
            <div className="control">
              <input
                className="input"
                type="number"
                inputMode="numeric"
                value={daysInVegetation}
                onChange={(e) => setDaysInVegetation(parseInt(e.target.value))} />
            </div>
          </div>
          <div className="field">
            <label className="label">Default Tray Substrate:</label>
            <div className="control">
              <input className="input" value={defaultTraySubstrate} onChange={(e) => setDefaultTraySubstrate(e.target.value)} />
            </div>
          </div>
          <div className="field">
            <label className="label">Default Tray Size:</label>
            <div className="control">
              <input className="input" value={defaultTraySize} onChange={(e) => setDefaultTraySize(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
      {isEditing && (
      <div className="field">
      <br />
          <label className="label">Archived:</label>
          <div className="control">
            <label className="switch">
              <input type="checkbox" checked={isArchived} onChange={handleArchiveChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      )}
      <br />
      <br />
      {isEditing && (
        <button className="button is-danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>Delete</button>

      )}
      <br />
      <br />
    </form>
    </div>
  );

  };

  export default AddEditPlantType;
