import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { useParams } from 'react-router-dom';
import { waitForCurrentUser } from "../js_functions.js";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const customerFormStyle = {
  paddingTop: '30px',
  paddingLeft: '10px'
};
const AddEditCustomer = ({ isLoggedIn }) => {
  const { docID } = useParams();
  const [customerName, setCustomerName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [orders, setOrders] = useState([]);
  const [harvests, setHarvests] = useState([]);
  const [mailingAddress1, setMailingAddress1] = useState('');
  const [mailingAddress2, setMailingAddress2] = useState('');
  const [mailingCity, setMailingCity] = useState('');
  const [mailingState, setMailingState] = useState('');
  const [mailingZip, setMailingZip] = useState('');

  const [billingAddress1, setBillingAddress1] = useState('');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingZip, setBillingZip] = useState('');



  useEffect(() => {
    if (docID) {
      setIsEditing(true);
      loadCustomer(docID);
      loadOrders(docID);
      loadHarvests(docID);

    }
  }, [docID]);

  const loadCustomer = async (docID) => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const customerDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/customers', docID);
      const customerDoc = await getDoc(customerDocRef);
      if (customerDoc.exists()) {
        const customerData = customerDoc.data();
        setCustomerName(customerData.customerName);
        setMailingAddress1(customerData.mailingAddress.address1);
        setMailingAddress2(customerData.mailingAddress.address2);
        setMailingCity(customerData.mailingAddress.city);
        setMailingState(customerData.mailingAddress.state);
        setMailingZip(customerData.mailingAddress.zip);
        setBillingAddress1(customerData.billingAddress.address1);
        setBillingAddress2(customerData.billingAddress.address2);
        setBillingCity(customerData.billingAddress.city);
        setBillingState(customerData.billingAddress.state);
        setBillingZip(customerData.billingAddress.zip);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadOrders = async (docID) => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const userDocData = userDoc.data(); // Retrieve userDocData here

      const ordersCollectionRef = collection(getFirestoreDB(), "system", userDocData.system, "orders");
      const ordersQuery = query(ordersCollectionRef, where("customer", "==", docID));
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersList = [];

      await Promise.all(
        ordersSnapshot.docs.map(async (orderDoc) => {
          const orderData = orderDoc.data();
          let customerName = '';

          if (orderData.customer) {
            const customerDocRef = doc(
              getFirestoreDB(),
              'system',
              userDocData.system,
              'customers',
              orderData.customer
            );

            const customerDoc = await getDoc(customerDocRef);

            if (customerDoc.exists()) {
              customerName = customerDoc.data().customerName;
            }
          }

          const selectedHarvests = await Promise.all(
            orderData.selectedHarvests.map(async (harvestId) => {
              const harvestDocRef = doc(
                getFirestoreDB(),
                'system',
                userDocData.system,
                'harvests',
                harvestId
              );

              const harvestDoc = await getDoc(harvestDocRef);

              return {
                docID: harvestId,
                ...harvestDoc.data(),
              };
            })
          );

          ordersList.push({
            docID: orderDoc.id,
            ...orderData,
            customerName,
            selectedHarvests,
          });
        })
      );

      setOrders(ordersList);
    } catch (error) {
      console.log(error);
    }
  };


  const loadHarvests = async (docID) => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const harvestsCollectionRef = collection(getFirestoreDB(), "system", userDoc.data().system, "harvests");
      const harvestsQuery = query(harvestsCollectionRef, where("customer", "==", docID));
      const harvestsSnapshot = await getDocs(harvestsQuery);
      const harvestsList = harvestsSnapshot.docs.map(doc => ({ docID: doc.id, ...doc.data() }));
      setHarvests(harvestsList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Update the current customer
    const newCustomer = {
      customerName: customerName,
      mailingAddress: {
        address1: mailingAddress1,
        address2: mailingAddress2,
        city: mailingCity,
        state: mailingState,
        zip: mailingZip,
      },
      billingAddress: {
        address1: billingAddress1,
        address2: billingAddress2,
        city: billingCity,
        state: billingState,
        zip: billingZip,
      },
    };

    const userID = await getFirestoreAuth().currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID);

    try {
      const userDoc = await getDoc(userDocRef);
      if (isEditing) {
        const customerDocRef = doc(getFirestoreDB(), "system", userDoc.data().system, "customers", docID);
        await updateDoc(customerDocRef, newCustomer); // Update the document with newCustomer data
      } else {
        const customersCollectionRef = collection(
          getFirestoreDB(),
          "system",
          userDoc.data().system,
          "customers"
        );
        newCustomer.display = true;

        await setDoc(doc(customersCollectionRef), newCustomer); // Create a new document with newCustomer data
      }

      // Reload the page or navigate to the desired location
      history.push("/customers");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
const handleHarvestClick = (harvestId) => {
  history.push(`/harvest?id=${harvestId}`);
  window.location.reload();
};
const handleOrderClick = (harvestId) => {
  history.push(`/add_edit_order/${harvestId}`);
  window.location.reload();
};

const handleDelete = async () => {
  try {
    const currentUser = await waitForCurrentUser();
    const userID = currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID);
    const userDoc = await getDoc(userDocRef);
    const customerDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/customers', docID);
    if (customerDocRef) {
      await updateDoc(customerDocRef, {
        display: false // Set the "display" attribute to false
      });
    }

    history.push("/customers");
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};
// Convert Firebase Firestore timestamp to a readable format
const formatTimestamp = (timestamp) => {
  const date = timestamp.toDate();
  return date.toLocaleDateString();
};
const handleCancel = () => {
  history.back(); // Go back to the previous page
};
return (
  <div style={customerFormStyle}>
    <div style={{display:'grid', gridTemplateColumns: '1.2in 1.1in 1.1in', gridColumnGap: '0.3in'}}>
      <div className="title is-5">{isEditing ? 'Edit Customer' : 'Add Customer'}</div>
      <button className="button is-success" onClick={handleSubmit}>{isEditing ? 'Save' : 'Add'}</button>
      <button className="button" type="button" onClick={handleCancel}>Cancel</button>
    </div>
    <br />
    <div style={{ width: '4in' }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 1fr' }}>
          <label className="label">Customer Name:</label>
            <input className="input" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
        </div>
        <br />
        <div className="title is-5">Mailing Address</div>
        <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 1fr' }}>
          <label className="label">Address 1:</label><input className="input" value={mailingAddress1} onChange={(e) => setMailingAddress1(e.target.value)} />
          <label className="label">Address 2:</label><input className="input" value={mailingAddress2} onChange={(e) => setMailingAddress2(e.target.value)} />
          <label className="label">City:</label><input className="input" value={mailingCity} onChange={(e) => setMailingCity(e.target.value)} />
          <label className="label">State:</label><input className="input" value={mailingState} onChange={(e) => setMailingState(e.target.value)} />
          <label className="label">Zip:</label><input className="input" value={mailingZip} onChange={(e) => setMailingZip(e.target.value)} />
        </div>
        <br />
        <div className="title is-5">Billing Address</div>
        <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 1fr' }}>
          <label className="label">Address 1:</label><input className="input" value={billingAddress1} onChange={(e) => setBillingAddress1(e.target.value)} />
          <label className="label">Address 2:</label><input className="input" value={billingAddress2} onChange={(e) => setBillingAddress2(e.target.value)} />
          <label className="label">City:</label><input className="input" value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
          <label className="label">State:</label><input className="input" value={billingState} onChange={(e) => setBillingState(e.target.value)} />
          <label className="label">Zip:</label><input className="input" value={billingZip} onChange={(e) => setBillingZip(e.target.value)} />
        </div>
        <br />
      </form>
    </div>
    {/* Display associated orders */}
    {isEditing && (
      <div>
        <h3>Associated Orders</h3>
        {orders.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Sale Amount</th>
                <th>Status</th>
                <th>Created At</th>
                {/* Add more columns if needed */}
              </tr>
            </thead>
            <tbody>
            {orders.map((order) => (
              <React.Fragment key={order.docID}>
                <tr key={order.docID} onClick={() => handleOrderClick(order.docID)}>
                  <td>...{order.docID.substr(-8)}</td>
                  <td>{order.saleAmount}</td>
                  <td>{order.status}</td>
                  <td>{formatTimestamp(order.createdAt)}</td>
                </tr>
                <tr>
                  <th></th>
                  <th style={{ fontStyle: 'italic' }}>Selected Harvests</th>
                  <th></th>
                  <th></th>
                </tr>
                {order.selectedHarvests.map((harvest) => (
                  <tr key={harvest.docID} onClick={() => handleHarvestClick(harvest.docID)} style={{ backgroundColor: 'lightgreen' }}>
                    <td></td>
                    <td>{harvest.harvestName}</td>
                    <td>{harvest.status}</td>
                    <td></td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
          </table>
        ) : (
          <p>No associated orders found.</p>
        )}
      </div>
    )}
    <br />
    <br />
    {isEditing && (
      <button className="button is-danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>Delete</button>
    )}
    <br />
    <br />
  </div>
);

};
export default AddEditCustomer;
