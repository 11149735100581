import React from 'react';

const Contact = () => {
  return (
    <div>
      <header className="navbar is-primary" role="navigation" aria-label="main navigation">
        {/* Navigation */}
        {/* Logo */}
      </header>
      <br />
      <div style={{paddingLeft: '0.4in'}}>
        <a href="/home" className="button is-link">Return Home</a>
      </div>
      <section className="section">
        <div className="container">
          {/* Support Articles */}
          <h2 className="title is-3">Contact Info</h2>
          <ul>
            <li>for inquires contct: ryan@thesproutreport.com</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Contact;
