// import React, { Component } from 'react'
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, setDoc, Timestamp } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { waitForCurrentUser } from "../js_functions.js"
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const add_harvest_form = {
  paddingTop: '30px',
  paddingLeft: '20px'
}

const scrollableContainer = {
  display: 'flex',
  overflowX: 'auto',
  gap: '20px' /* Optional, adds space between cards */
}
const cardStyle = {
  flex: '0 0 auto'
}
const exceptThisSymbols = ["e", "E", "+", "-"];
const AddHarvest = ({ plantId }) => {

  const [harvestName, setharvestName] = useState('');
  const [harvestPlantType, setharvestPlantType] = useState('');
  const [selectedPlantType, setSelectedPlantType] = useState('');
  const [harvestSeedAmount, setharvestSeedAmount] = useState('');
  const [sowDate, setsowDate] = useState('');
  const [vegetationStartDate, setvegetationStartDate] = useState('');
  const [projectedHarvestDate, setprojectedHarvestDate] = useState('');
  const [plantTypes, setPlantTypes] = useState([]);
  // const [selectedPlantType, setSelectedPlantType] = useState('');
  const [projectedYield, setprojectedYield] = useState(0);
  const [projectedYieldTotal, setprojectedYieldTotal] = useState(0.0);
  const [numberOfTrays, setNumberOfTrays] = useState(0);
  const [trays, setTrays] = useState([]);
  useEffect(() => {
    loadPlantTypes();
  }, []);

  useEffect(() => {
    // Calculate the newNumberOfTrays whenever projectedYieldTotal or projectedYield changes
    if (projectedYield > 0) {
      const newNumberOfTrays = Math.ceil(projectedYieldTotal / projectedYield); // Round up
      setNumberOfTrays(newNumberOfTrays);
    }
  }, [projectedYieldTotal, projectedYield]);

  const loadPlantTypes = async () => {
    try {
      let currentUser = await waitForCurrentUser();
      let userID = await currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const plantTypesQuery = query(
        collection(getFirestoreDB(), "system", userDoc.data().system, "plantTypes"),
        where("isArchived", "==", false), // Only fetch unarchived plant types
        where("display", "==", true) // Only fetch documents where display is true
      );
      const plantTypesSnapshot = await getDocs(plantTypesQuery);
      const plantTypesList = plantTypesSnapshot.docs.map(doc => ({ docID: doc.id, ...doc.data() }));
      setPlantTypes(plantTypesList);

    } catch (error) {
      console.log(error);
    }
  };

  const handlePlantTypeChange = (event) => {
    const plantType = plantTypes.find(plantType => plantType.docID === event.target.value);
    setharvestPlantType(event.target.value);
    setSelectedPlantType(plantType.docID);

    if (plantType) {
      setharvestSeedAmount(plantType.harvestSeedAmount);
      setprojectedYield(plantType.projectedYield);
    }
    const vegetationStartDateObject = new Date();
    if (sowDate)
    {
      const vegetationStartDateObject = new Date(sowDate);
      vegetationStartDateObject.setDate(vegetationStartDateObject.getDate() + plantType.daysInGermination);
      setvegetationStartDate(vegetationStartDateObject.toISOString().slice(0, 10));
    }else
    {
      const sowDateObject = new Date();
      setsowDate(sowDateObject.toISOString().slice(0, 10));
      vegetationStartDateObject.setDate(sowDateObject.getDate() + plantType.daysInGermination);
      setvegetationStartDate(vegetationStartDateObject.toISOString().slice(0, 10));
    }

    let days = plantType.daysInGermination + plantType.daysInVegetation;
    const projectedHarvestDateObject = new Date(vegetationStartDateObject.setDate(vegetationStartDateObject.getDate() + days));
    setprojectedHarvestDate(projectedHarvestDateObject.toISOString().slice(0, 10));
  };

  const handleSowDateChange = (event) => {
    setsowDate(event.target.value);
    if (harvestPlantType) {
      const plantType = plantTypes.find(plantType => plantType.docID === harvestPlantType);
      const sowDateObject = new Date(event.target.value);
      const vegetationStartDateObject = new Date(sowDateObject.setDate(sowDateObject.getDate() + plantType.daysInGermination));
      const projectedHarvestDateObject = new Date(vegetationStartDateObject.getTime());
      projectedHarvestDateObject.setDate(projectedHarvestDateObject.getDate() + plantType.daysInVegetation);

      setvegetationStartDate(vegetationStartDateObject.toISOString().slice(0, 10));
      setprojectedHarvestDate(projectedHarvestDateObject.toISOString().slice(0, 10));
    }
  };

  const handleVegetationDateChange = (event) => {
    setvegetationStartDate(event.target.value);
    if (harvestPlantType) {
      const plantType = plantTypes.find(plantType => plantType.docID === harvestPlantType);

      const projectedHarvestDateObject = new Date(event.target.value);
      projectedHarvestDateObject.setDate(projectedHarvestDateObject.getDate() + plantType.daysInVegetation);
      setprojectedHarvestDate(projectedHarvestDateObject.toISOString().slice(0, 10));

      const sowDateObject = new Date(event.target.value);
      sowDateObject.setDate(sowDateObject.getDate() - plantType.daysInGermination);
      setsowDate(sowDateObject.toISOString().slice(0, 10));

    }
  };

  const handleprojectedHarvestDateChange = (event) => {
    setprojectedHarvestDate(event.target.value);
    if (harvestPlantType) {
      const plantType = plantTypes.find(plantType => plantType.docID === harvestPlantType);

      const sowDateObject = new Date(event.target.value);
      let totalDays = plantType.daysInVegetation + plantType.daysInGermination
      sowDateObject.setDate(sowDateObject.getDate() - totalDays);
      setsowDate(sowDateObject.toISOString().slice(0, 10));

      const vegetationStartDateObject = new Date(event.target.value);
      vegetationStartDateObject.setDate(vegetationStartDateObject.getDate() - plantType.daysInVegetation);
      setvegetationStartDate(vegetationStartDateObject.toISOString().slice(0, 10));

    }
  };


const handleProjectedYieldTotalChange = (e) => {
  let inputValue = e.target.value;
  let newProjectedYieldTotal = 0
  // Check if inputValue is empty or a valid number




  const validFormat = /^[0-9]*\.?[0-9]*$/;

  // Check if the input matches the desired format
  if (validFormat.test(inputValue)) {
    // If it matches, update the state
    setprojectedYieldTotal(inputValue);
    if (inputValue === "") inputValue = 0;
    newProjectedYieldTotal = parseFloat(inputValue);
    let newNumberOfTrays = Math.ceil(newProjectedYieldTotal / projectedYield); // Round up
    handleNumberOfTraysChange({
      target: {
        value: newNumberOfTrays ?? 0
      },
    }, false);
  } else {
    // If it doesn't match, you can handle it accordingly
    // For example, you can set an error state to display an error message to the user.
    // setErrorState(true);

  }
};

  const handleNumberOfTraysChange = (event, shouldUpdateProjectedYieldTotal) => {
    const newNumberOfTrays = parseInt(event.target.value ?? 0);
    setNumberOfTrays(newNumberOfTrays);

    if (newNumberOfTrays > 0 && shouldUpdateProjectedYieldTotal) {
      let newProjectedYieldTotal = (projectedYield * newNumberOfTrays).toFixed(3); // Round to 3 decimal places
      setprojectedYieldTotal(newProjectedYieldTotal);
    }
    let newTrays = [];
    const plantType = plantTypes.find(plantType => plantType.docID === selectedPlantType);

    for (let i = 0; i < event.target.value; i++) {
      newTrays.push({
        trayId: i,
        customID: "",
        substrate: plantType ? plantType.defaultTraySubstrate : '',
        traySize: plantType ? plantType.defaultTraySize : '', // Added this line
      });
    }
    setTrays(newTrays);
  };


  const handleTrayChange = (index, key, value) => {
    let newTrays = [...trays];
    newTrays[index][key] = value;

    setTrays(newTrays);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const plantType = plantTypes.find(plantType => plantType.docID === harvestPlantType);

    if (!harvestSeedAmount)
      setharvestSeedAmount(0)
    if (!projectedYield)
      setprojectedYield(0)
    if (!projectedYield)
      setprojectedYield(0)

      const newHarvest = {
        harvestName: harvestName,
        display: true,
        status: 'ready_to_sow',
        harvestPlantType: plantType.plantTypeName,
        harvestPlantTypeVariation: plantType.variation,
        harvestPlantTypeDocID: selectedPlantType, // Save the selected plantType docID
        harvestSeedAmount: harvestSeedAmount,
        projectedYield: projectedYield,
        projectedYieldTotal: projectedYieldTotal,
        sowDate: sowDate ? Timestamp.fromDate(new Date(sowDate + "T00:00:00")) : null,
        vegetationStartDate: vegetationStartDate ? Timestamp.fromDate(new Date(vegetationStartDate + "T00:00:00")) : null,
        projectedHarvestDate: projectedHarvestDate ? Timestamp.fromDate(new Date(projectedHarvestDate + "T00:00:00")) : null,
        numberOfTrays: numberOfTrays
      };

    let userID = await getFirestoreAuth().currentUser.uid
    const userDocRef = doc(getFirestoreDB(), "users", userID)

    try {
      const userDoc = await getDoc(userDocRef)
      const harvestsDocs = collection(getFirestoreDB(), "system", userDoc.data().system, 'harvests');
      const newHarvestRef = doc(harvestsDocs);  // Create a reference for the new document
      await setDoc(newHarvestRef, newHarvest);  // Save the new document

      // Get the id of the new harvest
      const newHarvestId = newHarvestRef.id;

      // Create tray documents
      for (let tray of trays) {
        const trayData = {
          harvestId: newHarvestId,
          customID: tray.customID,
          substrate: tray.substrate,
          traySize: tray.traySize,
          trayPlantTypeDocID: selectedPlantType,
          originalPlantType: 1,
          // ... rest of the tray data
          ...newHarvest
        };
        const traysCollection = collection(getFirestoreDB(), "system", userDoc.data().system, 'trays');
        await setDoc(doc(traysCollection), trayData);
      }

    } catch(error) {
      console.log(error)
    }

    history.push("/harvests");
    window.location.reload()
  };

  const handleCancel = () => {
    history.back(); // Go back to the previous page
  };
    return (
      <div style={add_harvest_form}>
        <div style={{display:'grid', gridTemplateColumns: '1in 1.1in 1.1in', gridColumnGap: '0.25in'}}>
          <div className="title is-5">Add Harvest</div>
          <button className="button is-success"onClick={handleSubmit}>Add</button>
          <button className="button" type="button" onClick={handleCancel}>Cancel</button>
        </div>
        <br></br>
        <form onSubmit={handleSubmit}>
          <div style={{ width: '2.2in' }}>
            <div>
              <div className="field">
                <label className="label">Harvest Name:</label>
                <div className="control">
                  <input className="input" value={harvestName} onChange={(e) => setharvestName(e.target.value)} />
                </div>
              </div>
              <div className="field">
                <label className="label">Plant Type:</label>
                <div className="control">
                  <div className="select">
                    <select value={harvestPlantType} onChange={(e) => handlePlantTypeChange(e)}>
                      <option value=''></option>
                      {plantTypes.map((item, i) => {
                        if (item.docID === harvestPlantType) {
                          return (
                            <option selected={true} key={i} value={item.docID}>
                              {item.plantTypeName} - {item.variation} (Seed: {item.harvestSeedAmount}, Projected Yield: {item.projectedYield})
                            </option>
                          );
                        } else {
                          return (
                            <option key={i} value={item.docID}>
                              {item.plantTypeName} - {item.variation} (Seed: {item.harvestSeedAmount}, Projected Yield: {item.projectedYield})
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Seed Amount<br />per tray(lbs):</label>
                <div className="control">{harvestSeedAmount}</div>
              </div>
              <div className="field">
                <label className="label">Projected Yield<br />per tray(lbs):</label>
                <div className="control">{projectedYield}</div>
              </div>
              <div className="field">
                <label className="label">Sow Date:</label>
                <div className="control">
                  <input className="input" type="date" value={sowDate} onChange={(e) => handleSowDateChange(e)} />
                </div>
              </div>
              <div className="field">
                <label className="label">Vegetation Start Date:</label>
                <div className="control">
                  <input className="input" type="date" value={vegetationStartDate} onChange={(e) => handleVegetationDateChange(e)} />
                </div>
              </div>
              <div className="field">
                <label className="label">Projected Harvest Date:</label>
                <div className="control">
                  <input className="input" type="date" value={projectedHarvestDate} onChange={(e) => handleprojectedHarvestDateChange(e)} />
                </div>
              </div>
              <div className="field">
              <label className="label">Projected Yield Total:</label>
              <div className="control">
                {selectedPlantType ? (
                  <input
                    className="input"
                    type="text"
                    inputMode="decimal"
                    pattern="^[0-9]*\.?[0-9]*$"
                    value={projectedYieldTotal}
                    onChange={handleProjectedYieldTotalChange}
                  />
                ) : (
                  <span>Select a plant type to enable this field</span>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label">Number of Trays:</label>
              <div className="control">
                {selectedPlantType ? (
                  <input
                    className="input"
                    type="number"
                    min="0"
                    inputMode="numeric"
                    value={parseInt(numberOfTrays)}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => handleNumberOfTraysChange(e, true)}
                  />
                ) : (
                  <span>Select a plant type to enable this field</span>
                )}
              </div>
            </div>
              <br />

            </div>
          </div>
          <div style={scrollableContainer}>
            {trays.map((tray, index) => (
              <div key={index} className="card" style={cardStyle}>
                <nav className="card-header" style={{backgroundColor: 'rgb(52, 235, 143)'}}>

                  <p className="card-header-title">
                    Tray {index+1}
                  </p>
                </nav>
                <div className="card-content">
                  <div className="field">
                    <label className="label">Tray {index+1} ID:</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        value={tray.customID}
                        onChange={e => handleTrayChange(index, 'customID', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Substrate:</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        value={tray.substrate}
                        onChange={e => handleTrayChange(index, 'substrate', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Tray Size:</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        value={tray.traySize}
                        onChange={e => handleTrayChange(index, 'traySize', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          <br />
          <br />
          <br />

        </form>
      </div>
    )
}


export default AddHarvest;


