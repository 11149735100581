import React from "react";
import { ref, onValue, child, get, once, query} from "firebase/database";
import { getRTDB, getFirestoreAuth } from "../firebase.js";
import LineChart from './LineChart.js';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
// getFirestoreAuth()

function return_time_frame_query_string (time_frame) {

  let time_frame_query_string_result = '';

  // Return today's date and time
  let currentTime = new Date()
  // returns the month (from 0 to 11)
  let month = currentTime.getMonth() + 1
  if (month < 10)
    month = '0' + month.toString()
  else
    month = month.toString()
  // returns the day of the month (from 1 to 31)
  let day = currentTime.getDate().toString()
  if (day < 10)
    day = '0' + day.toString()
  else
    day = day.toString()
  // returns the year (four digits)
  let year = currentTime.getFullYear().toString()

  if (time_frame === 'today')
    time_frame_query_string_result = year + '/' + month + '/' + day;
  else if (time_frame === 'week')
    time_frame_query_string_result = year + '/' + month;
  else if (time_frame === 'month')
    time_frame_query_string_result = year + '/' + month;
  else if (time_frame === 'ytd')
    time_frame_query_string_result = year;
  else if (time_frame === 'all')
    time_frame_query_string_result = '/';
  return time_frame_query_string_result;
}
const header_content = {
  paddingLeft: '50px',
}
class TempuratureChart extends React.Component {
  constructor(props) {
    super(props);
    this.loadDataToLineChart = this.loadDataToLineChart.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.loadDataToLineChart()
    if(!this.props.isLoggedIn) {
      history.push("/login");
      window.location.reload()
    }
  }

  loadDataToLineChart = async () => {

    let label_array = []
    let temp_data_array = []
    let humidity_data_array = []
    let time_frame = document.getElementById("time_frame_select").value;

    let sevenDaysPrior = new Date()
    sevenDaysPrior.setDate(sevenDaysPrior.getDate() - 7);
    sevenDaysPrior =  Math.floor(sevenDaysPrior / 1000)


    let time_frame_query_string = return_time_frame_query_string(time_frame);

    const doc_refs = query(ref(getRTDB(), '/blips/ryan/' + time_frame_query_string))
    get(doc_refs).then((snapshot) => {
      snapshot.forEach((child) => {
        if (time_frame === 'today')
        {
          label_array.push(child.val().timestamp)
          temp_data_array.push(child.val().temperature)
          humidity_data_array.push(child.val().humidity)
        }else if (time_frame === 'week')
        {
          child.forEach((child) => {
            if (Math.floor(new Date(child.val().timestamp).getTime() / 1000) > sevenDaysPrior)
            {
              label_array.push(child.val().timestamp)
              temp_data_array.push(child.val().temperature)
              humidity_data_array.push(child.val().humidity)
            }
          })
        }else if (time_frame === 'month')
        {
          child.forEach((child) => {
            label_array.push(child.val().timestamp)
            temp_data_array.push(child.val().temperature)
            humidity_data_array.push(child.val().humidity)
          })
        }else if (time_frame === 'ytd')
        {
          child.forEach((child) => {
            child.forEach((child) => {
              label_array.push(child.val().timestamp)
              temp_data_array.push(child.val().temperature)
              humidity_data_array.push(child.val().humidity)
            })
          })
        }else if (time_frame === 'all')
        {
          child.forEach((child) => {
            child.forEach((child) => {
              child.forEach((child) => {
                label_array.push(child.val().timestamp)
                temp_data_array.push(child.val().temperature)
                humidity_data_array.push(child.val().humidity)
              })
            })
          })
        }
      });
    }).then(() => {
      let newState = {
        temperature: {
          labels: label_array,
          datasets: [
            {
              label: "Temperature",
              data: temp_data_array,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)"
            }
          ]
        },
        humidity: {
          labels: label_array,
          datasets: [
            {
              label: "Humidity",
              data: humidity_data_array,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)"
            }
          ]
        }
      };
      this.setState(newState, () => {
        let temp_count = this.state.temperature.datasets[0].data.length
        let humidity_count = this.state.humidity.datasets[0].data.length

        //set the current data: ie the last index of the arrays of temp/humidity/label_array
        document.getElementById('temperature').innerHTML=this.state.temperature.datasets[0].data[temp_count - 1];
        document.getElementById('humidity').innerHTML=this.state.humidity.datasets[0].data[humidity_count - 1];
        document.getElementById('time').innerHTML=label_array[temp_count - 1];


      })

    }).catch((error) => {
      console.error(error);
    });
    return doc_refs
  };

  render() {
    // console.log(this.state.temperature)
    return (
      <div>
        <br></br>
        <div style={header_content}>
          <div style={{borderBottom: '1px solid', width: '2.5in'}}>Current Data</div>
          <div style={{fontWeight: 'bold'}}>Tempurature: <span  id="temperature">{}</span></div>
          <div style={{fontWeight: 'bold'}}>Humidity: <span id="humidity"></span></div>
          <div style={{fontWeight: 'bold'}}>Time: <span id="time"></span></div>
          <br></br>
          <br></br>
          <div style={{borderBottom: '1px solid', width: '2.5in'}}>Historical Data</div>
          <br></br>
          <div className="select">
            <select id="time_frame_select" onChange={this.loadDataToLineChart}>
              <option value="today">Today</option>
              <option value="week">Past Week</option>
              <option value="month">Past Month</option>
              <option value="ytd">YTD</option>
              <option value="all">All Time</option>
            </select>
          </div>
        </div>
        <LineChart id="line_chart" dataset={this.state.temperature}/>
        <LineChart id="line_chart" dataset={this.state.humidity}/>
      </div>
    );
}
}
export default TempuratureChart;
