import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, setDoc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { useParams } from 'react-router-dom';
import { waitForCurrentUser } from "../js_functions.js";
import Select from 'react-select';


import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const orderFormStyle = {
  paddingTop: '30px',
  paddingLeft: '20px'
};
const customerOptionsList = [
  { value: '1', label: 'Customer 1' },
  { value: '2', label: 'Customer 2' },
  // Add more options as needed
];
const AddEditOrder = ({ isLoggedIn }) => {
  const { docID } = useParams();
  const [customerName, setCustomerName] = useState('');
  const [status, setStatus] = useState('');
  const [statusOptions, setstatusOptions] = useState({
    1: {displayName: 'Open',valueName: 'open'},
    2: {displayName: 'Ready to Invoice',valueName: 'ready_to_invoice'},
    3: {displayName: 'Invoice Sent',valueName: 'invoice_sent'},
    4: {displayName: 'Payment Received',valueName: 'payment_received'},
    5: {displayName: 'Closed Out',valueName: 'closed_out'},
  });
  const [saleAmount, setSaleAmount] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedHarvests, setSelectedHarvests] = useState([]);
  const [availableHarvests, setAvailableHarvests] = useState([]);
  const [allHarvests, setAllHarvests] = useState([]);
  const [initialSelectedHarvestIds, setinitialSelectedHarvestIds] = useState([]);
  const [isArchived, setIsArchived] = useState(false); // State for archived status
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);




  useEffect(() => {
    if (docID) {
      setIsEditing(true);
      loadOrder(docID).then(availableHarvestsList => {
        // setAvailableHarvests(availableHarvestsList);
      loadAvailableHarvests();
      });
    } else {
      loadAvailableHarvests();
    }
    loadCustomerOptions();

  }, [docID]);

  const loadCustomerOptions = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const customersCollection = collection(
        getFirestoreDB(),
        "system",
        userDoc.data().system,
        "customers"
      );

      const customersSnapshot = await getDocs(customersCollection);
      const customersList = customersSnapshot.docs.map((doc) => ({
        value: doc.id, // Use the document ID as the value for the option
        label: doc.data().customerName // Use the customer name as the label for the option
      }));

      setCustomerOptions(customersList);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAvailableHarvests = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const ordersCollection = collection(
        getFirestoreDB(),
        "system",
        userDoc.data().system,
        "orders"
      );

      const querySnapshot = await getDocs(ordersCollection);
      const selectedHarvestIds = new Set();

      querySnapshot.forEach((doc) => {
        const orderData = doc.data();
        orderData.selectedHarvests.forEach((harvestId) => {
          selectedHarvestIds.add(harvestId);
        });
      });

      const harvestsCollection = collection(
        getFirestoreDB(),
        "system",
        userDoc.data().system,
        "harvests"
      );

      // Add where filters to exclude archived and non-displayed harvests
      const harvestsQuery = query(harvestsCollection,
        where("display", "==", true),
        where("status", "!=", "harvest_complete"));

      const harvestsSnapshot = await getDocs(harvestsQuery);
      const harvestsList = harvestsSnapshot.docs.map((doc) => ({
        docID: doc.id,
        ...doc.data(),
      }));

      setAllHarvests(harvestsList);

      const availableHarvestsList = harvestsList.filter(
        (harvest) => !selectedHarvestIds.has(harvest.docID)
      );
      setAvailableHarvests(availableHarvestsList);
    } catch (error) {
      console.log(error);
    }
  };

  const loadOrder = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const ordersCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "orders");

      const orderDoc = await getDoc(doc(ordersCollection, docID));

      if (orderDoc.exists()) {
        const orderData = orderDoc.data();

        if (orderData.customerName)
          setCustomerName(orderData.customerName);
        else
        {
          // Retrieve the customer document to get the customer name
          const customerDoc = await getDoc(doc(getFirestoreDB(), "system", userDoc.data().system, "customers", orderData.customer));
          if (customerDoc.exists()) {
            const customerData = customerDoc.data();
            setCustomerName(customerData.customerName);
          } else {
            console.log("Error: Customer not found");
          }
        }



        setStatus(orderData.status);
        setSaleAmount(orderData.saleAmount);
        setSelectedOption({ value: orderData.customer, label: orderData.customerName });
        // Convert the array of docIDs to an array of objects
        const selectedHarvestsArray = orderData.selectedHarvests.map(docID => ({ docID }));
        setSelectedHarvests(selectedHarvestsArray);
        setinitialSelectedHarvestIds(orderData.selectedHarvests);
        setIsArchived(orderData.isArchived);
      } else {
        console.log("Error: Order not found");
      }

      const querySnapshot = await getDocs(ordersCollection);
      const selectedHarvestIds = new Set();

      querySnapshot.forEach((doc) => {
        const orderData = doc.data();
        orderData.selectedHarvests.forEach((harvestId) => {
          selectedHarvestIds.add(harvestId);
        });
      });

      const harvestsCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "harvests");

      const harvestsQuery = query(harvestsCollection, where("display", "==", true));

      const harvestsSnapshot = await getDocs(harvestsQuery);
      const harvestsList = harvestsSnapshot.docs.map((doc) => ({
        docID: doc.id,
        ...doc.data(),
      }));

      setAllHarvests(harvestsList);

      const availableHarvestsList = harvestsList.filter(
        (harvest) => !selectedHarvestIds.has(harvest.docID)
      );

      setAvailableHarvests(availableHarvestsList);
      return availableHarvestsList; // Return the available harvests list to be used in the component state
    } catch (error) {
      console.log(error);
    }
  };



  const handleHarvestSelection = async (harvestId, harvestName, isSelected) => {
    if (isSelected) {
      try {
        const currentUser = await waitForCurrentUser();
        const userID = currentUser.uid;
        const userDocRef = doc(getFirestoreDB(), "users", userID);
        const userDoc = await getDoc(userDocRef);
        const ordersCollectionRef = collection(
          getFirestoreDB(),
          "system",
          userDoc.data().system,
          "orders"
        );

        if (!initialSelectedHarvestIds.includes(harvestId)) {
          const querySnapshot = await getDocs(
            query(ordersCollectionRef, where("selectedHarvests", "array-contains", harvestId))
          );
          if (!querySnapshot.empty) {
            console.log("Error: The Harvest is already associated with another Order.");
            return;
          }
        }
        setSelectedHarvests(prevSelectedHarvests => [
          ...prevSelectedHarvests,
          { docID: harvestId, harvestName: harvestName }
        ]);
        setAvailableHarvests(prevAvailableHarvests =>
          prevAvailableHarvests.filter(harvest => harvest.docID !== harvestId)
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setSelectedHarvests(prevSelectedHarvests =>
        prevSelectedHarvests.filter(harvest => harvest.docID !== harvestId)
      );
      setAvailableHarvests(prevAvailableHarvests => [
        ...prevAvailableHarvests,
        { docID: harvestId, harvestName: harvestName }
      ]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedSaleAmount = Number(saleAmount).toFixed(2);
    const selectedHarvestIds = selectedHarvests.map((harvest) => harvest.docID);

    // Get the current timestamp
    const timestamp = serverTimestamp();

    // Retrieve the customer document ID based on the selected customer name
    let customerId = null;
    if (selectedOption) {
      customerId = selectedOption.value;

    }

    // Update the current order
    const newOrder = {
      customer: customerId,
      saleAmount: formattedSaleAmount,
      selectedHarvests: selectedHarvestIds,
      status: status ? status : 'open',
      createdAt: timestamp,
      isArchived: isArchived,
    };

    const userID = await getFirestoreAuth().currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID);

    try {
      const userDoc = await getDoc(userDocRef);


      if (isEditing) {
        const orderDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + "/orders", docID);
        await updateDoc(orderDocRef, newOrder);
      } else {
        const ordersCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "orders");
        newOrder.display = true;
        await setDoc(doc(ordersCollection), newOrder);
      }

      // Reload the page
      history.push("/orders");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };


  const handleDelete = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const orderDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/orders', docID);
      const orderDoc = await getDoc(orderDocRef);
      if (orderDoc.exists()) {
        await updateDoc(orderDocRef, {
          display: false // Set the "display" attribute to false
        });
      } else {
        await setDoc(orderDocRef, {
          display: false // Create the document with the "display" attribute set to false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleArchiveChange = (event) => {
    setIsArchived(event.target.checked); // Set the value of isArchived based on the checked state of the checkbox
  };
  const handleCancel = () => {
    history.back(); // Go back to the previous page
  };

  const handleEditClick = async (docID) => {
    history.push(`/add_edit_order/${docID}`);

    const availableHarvestsList = await loadOrder(docID);

    if (availableHarvestsList && availableHarvestsList.length > 0) {
      setAvailableHarvests(availableHarvestsList);
    }
  };
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setCustomerName(selectedOption.label ? selectedOption.label : '');
  };
  return (
    <div style={orderFormStyle}>
      <div style={{ display: 'grid', gridTemplateColumns: '1in 1.1in 1.1in', gridColumnGap: '0.25in' }}>
        <div className="title is-5">{isEditing ? 'Edit Order' : 'Add Order'}</div>
        <button className="button is-success" onClick={handleSubmit}>{isEditing ? 'Save' : 'Add'}</button>
        <button className="button" type="button" onClick={handleCancel}>Cancel</button>
      </div>

      <br />
      {isEditing && (
        <div className="title is-5">Order ID: ...{docID.substr(-8)}</div>
        )}
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'grid', gridTemplateColumns: '1.8in 2in', gridTemplateRows: '1fr', gridColumnGap: '10px' }}>
          <div>
          <label className="label">Customer Name: {customerName}</label>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={customerOptions}
            placeholder="Select a customer"
          />
          <br />
            <br />
            <label className="label">Status:</label>
            <select className="select" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
              {Object.entries(statusOptions).map(([key, option]) => (
                <option key={key} value={option.valueName}>
                  {option.displayName}
                </option>
              ))}
            </select>
            <br />
            <label className="label">Sale Amount: $</label>
            <input
              className="input"
              type="text"
              inputMode="decimal"
              value={saleAmount}
              onChange={(e) => {
                // Allow only numeric and decimal point input
                const inputValue = e.target.value;
                if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
                  setSaleAmount(inputValue);
                }
              }}
              onBlur={() => {
                // Format the value on blur
                if (saleAmount !== '') {
                  setSaleAmount(Number(saleAmount).toFixed(2));
                }
              }}
            />
            <br />
            <label className="label">Selected Harvests:</label>
            {selectedHarvests.length > 0 && (
              <div>
                {selectedHarvests.map((selectHarvest) => {
                  const selectedHarvest = allHarvests.find((harvest) => harvest.docID === selectHarvest.docID);
                  if (!selectedHarvest) {
                    return null; // Skip rendering if the selected harvest is not found in the allHarvests list
                  }
                  return (
                    <div key={selectHarvest.docID}>
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => handleHarvestSelection(selectHarvest.docID, selectedHarvest.harvestName, false)}
                      />
                      {selectedHarvest.harvestName}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <label className="label">Available Harvests:</label>
            {(availableHarvests || []).map((harvest) => (
              <div key={harvest.docID}>
                <input
                  type="checkbox"
                  checked={selectedHarvests.includes(harvest.docID)}
                  onChange={(e) => handleHarvestSelection(harvest.docID, harvest.harvestName, e.target.checked)}
                />
                {harvest.harvestName}
              </div>
            ))}
          </div>
        </div>
        {isEditing && (
        <div className="field">
        <br />
            <label className="label">Archived:</label>
            <div className="control">
              <label className="switch">
                <input type="checkbox" checked={isArchived} onChange={handleArchiveChange} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
        {isEditing && (
          <button className="button is-danger" onClick={handleDelete}>Delete</button>
        )}
        <br />
        <br />
      </form>
    </div>
  );

};
export default AddEditOrder;
