import React, { useState, useEffect } from "react";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { collection, query, where, getDocs, getDoc, doc, orderBy, Timestamp } from "firebase/firestore";
import { waitForCurrentUser } from "../js_functions.js"
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const harvests_page = {
  paddingTop: '30px',
  paddingLeft: '10px',
};

const harvests_header = {
  display: 'grid',
  gridTemplateColumns: '1.7in 1.5in',
  marginBottom: '50px',
};

const tableContainerStyle = {
  maxWidth: '100%',
  overflowX: 'auto',
};

const harvest_table = {
  borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: 'white',
};

const tableCell = {
  border: '1px solid grey',
  padding: '8px',
};

const harvest_list_header = {
  textAlign: 'left',
  paddingLeft: '10px'
}

const Harvests = (props) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const futureDate = new Date();
  futureDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00
  futureDate.setDate(futureDate.getDate() + 30);

  const [harvests, setHarvests] = useState([]);
  const [sortField, setSortField] = useState('projectedHarvestDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [startDate, setStartDate] = useState(oneMonthAgo.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(futureDate.toISOString().split('T')[0]);
  const [dateColumn, setDateColumn] = useState('sowDate');
  const [statusFilter, setStatusFilter] = useState([]);


  useEffect(() => {
    if (!props.isLoggedIn) {
      history.push("/login");
      window.location.reload();
    }
    loadHarvests('sowDate', 'desc');
  }, [props.isLoggedIn, dateColumn, statusFilter, startDate, endDate]);


  const handleSortChange = (field) => {
    let newSortOrder = 'desc';
    if (field === sortField) {
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  const getHeaderStyle = (field) => {
    if (field === sortField) {
      return {
        ...harvest_list_header,
        textAlign: 'left',
        paddingRight: '10px',

      };
    }
    return {
      ...harvest_list_header,
      textAlign: 'left',
      paddingRight: '10px',
    };
  };

  const loadHarvests = async (sortField, sortOrder) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
    handleSortChange(dateColumn);
    setHarvests([]);
    let currentUser = await waitForCurrentUser();
    let userID = await currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID);
    const userDoc = await getDoc(userDocRef);
    const harvestsRef = collection(getFirestoreDB(), "system", userDoc.data().system, 'harvests');
    const isDateColumn = ['sowDate', 'vegetationStartDate', 'projectedHarvestDate'].includes(dateColumn);
    let baseQuery;

    if (isDateColumn) {
      baseQuery = query(harvestsRef, orderBy(dateColumn, sortOrder));
      if (startDate && endDate) {
        const startDateTimestamp = Timestamp.fromDate(new Date(startDate));
        const endDateTimestamp = Timestamp.fromDate(new Date(endDate));
        baseQuery = query(baseQuery, where(dateColumn, '>=', startDateTimestamp), where(dateColumn, '<=', endDateTimestamp));
      }
    } else {
      baseQuery = query(harvestsRef, orderBy(sortField, sortOrder));
    }

    const systemDocs = await getDocs(baseQuery);
    let newHarvests = [];
    systemDocs.forEach((doc) => {
      let harvestData = doc.data();
      harvestData.id = doc.id;
      switch (harvestData.status) {
        case 'sow_ready':
          harvestData.status = 'Sow Ready';
          break;
        case 'germination':
          harvestData.status = 'Germination';
          break;
        case 'vegetative':
          harvestData.status = 'Vegetative';
          break;
        case 'harvest_ready':
          harvestData.status = 'Harvest Ready';
          break;
        case 'harvest_complete':
          harvestData.status = 'Harvest Complete';
          break;
        default:
          break;
      }
      newHarvests.push(harvestData);
    });

    // Filter the harvests based on the 'display' field
    const filteredHarvests = newHarvests.filter((harvest) => harvest.display === true);

    setHarvests(filteredHarvests);
  };



  const addHarvestClick = event => {
    event.preventDefault();
    history.push("/add_harvest");
    window.location.reload()
  };
  const HarvestClick = harvest => {
    history.push("/harvest?id=" + harvest.id);
    window.location.reload()
  };
  const handleStatusFilterChange = (e) => {
    const checkbox = e.target;
    const value = checkbox.value;

    setStatusFilter((prevStatusFilter) => {
      if (prevStatusFilter.includes(value)) {
        // Remove the value from the filter
        return prevStatusFilter.filter((status) => status !== value);
      } else {
        // Add the value to the filter
        return [...prevStatusFilter, value];
      }
    });
  };
  return (
    <div style={harvests_page}>
      <div>
        <div className="title is-5">Harvests</div>
        <button className="button" onClick={addHarvestClick}>Add Harvest</button>
      </div>
      <br />
      <div style={harvests_header}>
        <div style={{ textAlign: 'left' }}>
          <div style={{width: '1.5in'}}>
            <label>Column:</label>
            <select className="select" onChange={(e) => setDateColumn(e.target.value)}>
              <option value="sowDate">Sow Date</option>
              <option value="vegetationStartDate">Vegetation Start Date</option>
              <option value="projectedHarvestDate">Projected Harvest Date</option>
            </select>
            <label>Date Range:</label><br />
            <input className="input" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /><br />
            to: <input className="input" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /><br />
          </div>
        </div>
        <div>
        <div>
          <label>Status:</label><br />
          <input type="checkbox" id="sowReady" value="sow_ready" onChange={handleStatusFilterChange} />
          <label htmlFor="sowReady">Sow Ready</label><br />
          <input type="checkbox" id="germination" value="germination" onChange={handleStatusFilterChange} />
          <label htmlFor="germination">Germination</label><br />
          <input type="checkbox" id="vegetative" value="vegetative" onChange={handleStatusFilterChange} />
          <label htmlFor="vegetative">Vegetative</label><br />
          <input type="checkbox" id="harvestReady" value="harvest_ready" onChange={handleStatusFilterChange} />
          <label htmlFor="harvestReady">Harvest Ready</label><br />
          <input type="checkbox" id="harvestComplete" value="harvest_complete" onChange={handleStatusFilterChange} />
          <label htmlFor="harvestComplete">Harvest Complete</label><br />
          <br />
          <br />
          <br />
          {/* <button className="button" onClick={() => loadHarvests(dateColumn, sortOrder)}>Apply Filter</button> */}
        </div>
        </div>
      </div>
      <div id="harvest_list" style={tableContainerStyle}>
        <table style={harvest_table}>
          <thead>
            <tr>
              {/* <th style={{ ...tableCell, ...getHeaderStyle('harvestName') }} onClick={() => handleSortChange('harvestName')}>
                Harvest Name {sortField === 'harvestName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={{ ...tableCell, ...getHeaderStyle('status') }} onClick={() => handleSortChange('status')}>
                Status {sortField === 'status' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th> */}
              <th style={{ ...tableCell, ...getHeaderStyle('harvestName') }}>
                Harvest Name
              </th>
              <th style={{ ...tableCell, ...getHeaderStyle('status') }}>
                Status
              </th>
              <th style={{ ...tableCell, ...getHeaderStyle('sowDate') }} onClick={() => handleSortChange('sowDate')}>
                Sow Date {sortField === 'sowDate' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={{ ...tableCell, ...getHeaderStyle('vegetationStartDate') }} onClick={() => handleSortChange('vegetationStartDate')}>
                Vegetation<br />Start Date {sortField === 'vegetationStartDate' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th style={{ ...tableCell, ...getHeaderStyle('projectedHarvestDate') }} onClick={() => handleSortChange('projectedHarvestDate')}>
                Projected<br />Harvest Date {sortField === 'projectedHarvestDate' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
          <tbody>
            {harvests
              .sort((a, b) => {
                if (sortOrder === 'asc') {
                  return a[sortField] - b[sortField];
                } else {
                  return b[sortField] - a[sortField];
                }
              })
              .map((item, i) => (
                <tr key={i} onClick={() => HarvestClick(item)}>
                  <td style={tableCell}>{item.harvestName}</td>
                  <td style={tableCell}>{item.status ? item.status : 'N/A'}</td>
                  <td style={tableCell}>{item.sowDate.toDate().toLocaleDateString()}</td>
                  <td style={tableCell}>{item.vegetationStartDate.toDate().toLocaleDateString()}</td>
                  <td style={tableCell}>{item.projectedHarvestDate.toDate().toLocaleDateString()}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
export default Harvests;
