import React from 'react';

const Community = () => {
  return (
    <div>
      <header className="navbar is-primary" role="navigation" aria-label="main navigation">
        {/* Navigation */}
        {/* Logo */}
      </header>
      <br />
      <div style={{paddingLeft: '0.4in'}}>
        <a href="/home" className="button is-link">Return Home</a>
      </div>
      <section className="section">
        <div className="container">
          {/* Support Articles */}
          <h2 className="title is-3">Support Articles</h2>
          <ul>
            <li>comming soon...</li>
          </ul>
        </div>
      </section>
      <section className="section">
        <div className="container">
          {/* Q&A Section */}
          <h2 className="title is-3">Q&A Forum</h2>
          <ul>
            <li>comming soon...</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Community;
