import React, { useState, useEffect } from 'react';
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { waitForCurrentUser } from "../js_functions.js";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const settingsPageStyle = {
  paddingTop: '30px',
  paddingLeft: '30px'
};

const Settings = () => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const settingsCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "settings");

      const settingsSnapshot = await getDocs(settingsCollection);
      const settingsList = settingsSnapshot.docs.map(doc => ({ docID: doc.id, ...doc.data() }));
      setSettings(settingsList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddClick = () => {
    history.push("/add_edit_settings");
    window.location.reload();
  };

  const handleEditClick = (docID) => {
    console.log(docID);
    history.push(`/add_edit_settings/${docID}`);
    window.location.reload();
  };

  return (
    <div style={settingsPageStyle}>
      <div className="title is-5">Settings</div>
      <button className="button" onClick={handleAddClick}>Add Setting</button>
      <br />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th>Setting Name</th>
            <th>Use as Main Setting</th>
          </tr>
        </thead>
        <tbody>
          {settings.map(setting => (
            <tr key={setting.docID} onClick={() => handleEditClick(setting.docID)} style={{cursor: 'pointer'}}>
              <td>{setting.settingName}</td>
              <td>{setting.useAsMainSetting ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Settings;
