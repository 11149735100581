import React, { Component } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { getFirestoreAuth, getFirestoreDB } from "../firebase.js";
import { collection, getDocs } from "firebase/firestore";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const fetchPost = async () => {
  await getDocs(collection(getFirestoreDB(), "users"))
  .then((querySnapshot)=>{
    console.log(querySnapshot.docs)
  })
}

class LoginPage extends Component {
  state = {
    email: '',
    password: '',
  };
  componentDidMount() {
    if(this.props.isLoggedIn) {
      history.push("/userhome");
      window.location.reload()
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleGoToHome = () => {
    history.push("/home");
    window.location.reload();
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state)

    signInWithEmailAndPassword(getFirestoreAuth(), this.state.email, this.state.password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      this.props.handleLogIn(user)
      fetchPost()
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  };

  passwordResetClick = event => {
    event.preventDefault();
    console.log('reset pass');
    this.props.resetPass(this.state.email);
  };
  render() {
    return (
      <div>
        <div className="box userLogin">
        <form onSubmit={this.handleSubmit}>
          <h3 className={'prayerReqTitle title is-3'}>User Login</h3>

          <div style={{'width': '3in'}}>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" className="input" onChange={this.handleChange} />
          </div>
          <br />
          <div style={{'width': '3in'}}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              className="input"
              onChange={this.handleChange}
            />
          </div>
          <br />
          <div className="input-field">
            <button className="button">Login</button>
          </div>
          <br />
          <br />
        </form>
        <div className="input-field">
          <p>for password reset...</p>
          <p>enter email above then click</p>
          <br />
          <button className="button" onClick={this.passwordResetClick}>
            Password Reset
          </button>
          <p>check email for reset link</p>
        </div>
        {/* {authError ? <p>{authError}</p> : null} */}
        <br />
        <br />
        <button className="button" onClick={this.handleGoToHome}>Go to Home</button>

      </div>

    </div>
    );
  }
}
export default LoginPage
