import { getFirestoreAuth } from "./firebase.js";

export const waitForCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const auth = getFirestoreAuth();

    if (auth.currentUser) {
      resolve(auth.currentUser);
    } else {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          unsubscribe(); // Detach the listener once the user is ready
          resolve(user);
        } else {
          reject(new Error('User is not logged in'));
        }
      });
    }
  });
};
