import React, { Component } from "react";
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";

const box = {
  display: 'flex',
  justifyContent: 'center'
}
const LineChartStyle= {
  height: '450px',
  width: '900px',
}

export default class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      datasets: []
    };
  }
  componentDidMount() {
    if(this.props.dataset){
      this.setState(this.props.dataset);
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.dataset !== this.props.dataset) {
      this.setState(this.props.dataset);
    }
  }
  render() {
    return (
      <div style={box}>
        <div style={LineChartStyle}>
          <Line  data={this.state} />
        </div>
      </div>
    );
  }
}
