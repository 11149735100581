import React, { Component } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestoreAuth, getFirestoreDB } from "../firebase.js";
import { collection, addDoc, query, where, getDocs, doc, setDoc } from "firebase/firestore";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

class SignUpPage extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    systemName: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleGoToHome = () => {
    history.push("/home");
    window.location.reload();
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);

    const { email, password, firstName, lastName, systemName } = this.state;

    const auth = getFirestoreAuth();

    // Check if the system name already exists in the "system" collection
    const systemCollection = collection(getFirestoreDB(), "system");
    const systemQuery = query(systemCollection, where("systemName", "==", systemName));

    getDocs(systemQuery)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // System name already exists, show a popup message to the user
          alert("System name already exists. Please choose another system name.");
        } else {
          // System name is unique, proceed with user sign-up
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // User signed up successfully
              const user = userCredential.user;
              console.log("User signed up:", user);

              // Save user record in the "users" collection with the auth user ID as the document ID
              const usersCollection = collection(getFirestoreDB(), "users");
              const userId = user.uid; // Custom document ID for the user
              const userDocRef = doc(usersCollection, userId);
              setDoc(userDocRef, {
                userId: userId,
                firstName: firstName,
                lastName: lastName,
                system: systemName,
              })
                .then(() => {
                  console.log("User record saved with ID:", userId);
                })
                .catch((error) => {
                  console.error("Error saving user record:", error);
                });

              // Save system record in the "system" collection with the document ID as the system name
              const systemDocRef = doc(systemCollection, systemName);
              setDoc(systemDocRef, {
                systemName: systemName,
                // Add additional system-related fields here if needed
              })
                .then(() => {
                  console.log("System record saved with ID:", systemName);
                  history.push("/home");
                  window.location.reload();
                })
                .catch((error) => {
                  console.error("Error saving system record:", error);
                });

              // Redirect the user to the login page after sign-up

            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log("Sign-up error:", errorCode, errorMessage);
              // Handle sign-up error (e.g., display error message to the user)
            });
        }
      })
      .catch((error) => {
        console.error("Error checking system name:", error);
      });
  };


  render() {
    return (
      <div>
        <div className="box userSignUp">
          <form onSubmit={this.handleSubmit}>
            <h3 className="prayerReqTitle title is-3">User Sign Up</h3>

            <div style={{ width: '3in' }}>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" className="input" onChange={this.handleChange} />
            </div>
            <br />
            <div style={{ width: '3in' }}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                className="input"
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div style={{ width: '3in' }}>
              <label htmlFor="firstName">First Name</label>
              <input type="text" name="firstName" className="input" onChange={this.handleChange} />
            </div>
            <br />
            <div style={{ width: '3in' }}>
              <label htmlFor="lastName">Last Name</label>
              <input type="text" name="lastName" className="input" onChange={this.handleChange} />
            </div>
            <br />
            <div style={{ width: '3in' }}>
              <label htmlFor="systemName">System Name</label>
              <input type="text" name="systemName" className="input" onChange={this.handleChange} />
            </div>
            <br />
            <div className="input-field">
              <button className="button">Sign Up</button>
            </div>
            <br />
            <br />
          </form>
          <button className="button" onClick={this.handleGoToHome}>Go to Home</button>

        </div>
      </div>
    );
  }
}

export default SignUpPage;
