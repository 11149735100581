import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where, Timestamp } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";

import { waitForCurrentUser } from "../js_functions.js";
import { createBrowserHistory } from 'history';
import AddEditPlantType from './AddEditPlantType';

const history = createBrowserHistory();

const ordersPageStyle = {
  paddingTop: '30px',
  paddingLeft: '10px'
};
const harvests_header = {
  display: 'grid',
  gridTemplateColumns: '1.7in 1.5in',
  marginBottom: '50px',
}

const Orders = () => {

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00
  currentDate.setDate(currentDate.getDate() - 30);
  const futureDate = new Date();
  futureDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00
  futureDate.setDate(futureDate.getDate() + 30);

  const startDateDefault = currentDate.toISOString().split('T')[0];
  const endDateDefault = futureDate.toISOString().split('T')[0];

  const [orders, setOrders] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('asc');
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);
  const [isArchivedFilterEnabled, setIsArchivedFilterEnabled] = useState(false); // State for the checkbox

  useEffect(() => {
    loadOrders();
  }, [statusFilters, sortColumn, sortOrder, startDate, endDate, isArchivedFilterEnabled]);

  const loadOrders = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const db = getFirestoreDB();
      const userDocRef = doc(db, 'users', userID);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userDocData = userDoc.data();
        const ordersCollectionRef = collection(
          db,
          'system',
          userDocData.system,
          'orders'
        );
        let ordersQuery = ordersCollectionRef;

        // Apply isArchived filter if enabled
        if (!isArchivedFilterEnabled) {
          const isArchivedFilter = where('isArchived', '==', false);
          ordersQuery = query(ordersQuery, isArchivedFilter);
        }
        // Add filter for display === true
        const displayFilter = where('display', '==', true);
        ordersQuery = query(ordersQuery, displayFilter);


        // Apply status filter if at least one status is selected
        if (statusFilters.length > 0) {
          const statusFilter = where('status', 'in', statusFilters);
          ordersQuery = query(ordersQuery, statusFilter);
        }

        const ordersSnapshot = await getDocs(ordersQuery);
        let ordersList = [];

        // Fetch customer data for each order asynchronously
        await Promise.all(
          ordersSnapshot.docs.map(async (orderDoc) => {
            const orderData = orderDoc.data();
            let customerName = '';

            if (orderData.customer) {
              const customerDocRef = doc(
                db,
                'system',
                userDocData.system,
                'customers',
                orderData.customer
              );

              const customerDoc = await getDoc(customerDocRef);

              if (customerDoc.exists()) {
                customerName = customerDoc.data().customerName;
              }
            }

            const selectedHarvests = await Promise.all(
              orderData.selectedHarvests.map(async (harvestId) => {
                const harvestDocRef = doc(
                  db,
                  'system',
                  userDocData.system,
                  'harvests',
                  harvestId
                );

                const harvestDoc = await getDoc(harvestDocRef);

                return {
                  docID: harvestId,
                  ...harvestDoc.data(),
                };
              })
            );

            ordersList.push({
              docID: orderDoc.id,
              ...orderData,
              customerName,
              selectedHarvests,
            });
          })
        );

        // Apply date range filter for createdAt column
        if (startDate && endDate) {
          const startOfDay = new Date(startDate + 'T00:00:00');
          const endOfDay = new Date(endDate + 'T23:59:59');
          ordersList = ordersList.filter((order) => {
            const createdAt = order.createdAt && order.createdAt.toDate();
            return (
              createdAt && createdAt >= startOfDay && createdAt <= endOfDay
            );
          });
        }

        // Sort the orders based on the selected column and order
        if (sortColumn) {
          ordersList.sort((a, b) => {
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];

            if (sortColumn === 'customerName') {
              // Case-insensitive sorting for customerName
              const nameA = String(valueA).toLowerCase();
              const nameB = String(valueB).toLowerCase();
              return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
            } else if (sortColumn === 'saleAmount') {
              // Numeric sorting for saleAmount
              return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
            } else {
              // Default string comparison for other columns
              return sortOrder === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
            }
          });
        }

        setOrders(ordersList);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleAddClick = () => {
    history.push("/add_edit_order");
    window.location.reload();
  };

  const handleEditClick = (docID) => {
    console.log(docID);
    history.push(`/add_edit_order/${docID}`);
    window.location.reload();
  };
  const handleHarvestClick = (docID) => {
    history.push("/harvest?id=" + docID);
    window.location.reload()
  };
  const SortIndicator = ({ sortOrder }) => {
    if (sortOrder === 'asc') {
      return <span>&#9650;</span>; // Up arrow
    } else {
      return <span>&#9660;</span>; // Down arrow
    }
  };
  const handleStatusFilterChange = (event) => {
    const selectedStatus = event.target.value;
    if (statusFilters.includes(selectedStatus)) {
      setStatusFilters(statusFilters.filter((status) => status !== selectedStatus));
    } else {
      setStatusFilters([...statusFilters, selectedStatus]);
    }
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const handleIsArchivedFilterChange = () => {
    setIsArchivedFilterEnabled(!isArchivedFilterEnabled);
  };
  return (
    <div style={ordersPageStyle}>
      <div className="title is-5">Orders</div>
      <button className="button" onClick={handleAddClick}>Add Order</button>
      <br />
      <div style={harvests_header}>
        <div style={{ textAlign: 'left' }}>
          <div style={{width: '1.5in'}}>
            <label>Column:</label>
            <select className="select" onChange={(e) => setSortColumn(e.target.value)}>
              <option value="createdAt">Created At</option>
            </select>
            <label>Date Range:</label><br />
            <input className="input" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /><br />
            to: <input className="input" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /><br />
          </div>
        </div>
        <div>
        <label>Status Filter:</label>
        <br />
        <label>
          <input
            type="checkbox"
            value="open"
            checked={statusFilters.includes("open")}
            onChange={handleStatusFilterChange}
          />
          Open
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="ready_to_invoice"
            checked={statusFilters.includes("ready_to_invoice")}
            onChange={handleStatusFilterChange}
          />
          Ready to Invoice
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="invoice_sent"
            checked={statusFilters.includes("invoice_sent")}
            onChange={handleStatusFilterChange}
          />
          Invoice Sent
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="payment_received"
            checked={statusFilters.includes("payment_received")}
            onChange={handleStatusFilterChange}
          />
          Payment Received
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="closed_out"
            checked={statusFilters.includes("closed_out")}
            onChange={handleStatusFilterChange}
          />
          Closed Out
        </label>
      </div>
      </div>
      <br />
      <label>
        <input type="checkbox" checked={isArchivedFilterEnabled} onChange={handleIsArchivedFilterChange} />
        Include Archived Records
      </label>
      <table className="table">
      <thead>
        <tr>
          <th onClick={() => handleSort('docID')}>Order ID {sortColumn === 'docID' && <SortIndicator sortOrder={sortOrder} />}</th>
          <th onClick={() => handleSort('customerName')}>Customer Name {sortColumn === 'customerName' && <SortIndicator sortOrder={sortOrder} />}</th>
          <th onClick={() => handleSort('saleAmount')}>Sale Amount {sortColumn === 'saleAmount' && <SortIndicator sortOrder={sortOrder} />}</th>
          <th onClick={() => handleSort('status')}>Status {sortColumn === 'status' && <SortIndicator sortOrder={sortOrder} />}</th>
          <th onClick={() => handleSort('createdAt')}>Created At {sortColumn === 'createdAt' && <SortIndicator sortOrder={sortOrder} />}</th>
        </tr>
      </thead>
        <tbody>
        {orders.map((order) => (
          <React.Fragment key={order.docID}>
            <tr onClick={() => handleEditClick(order.docID)} style={{ cursor: 'pointer' }}>
              <td>...{order.docID.substr(-8)}</td>
              <td>{order.customerName}</td>
              <td>${order.saleAmount}</td>
              <td>{order.status ? order.status : ''}</td>
              <td>{order.createdAt ? order.createdAt.toDate().toLocaleString() : 'N/A'}</td>
            </tr>
            <tr style={{ fontSize: '13px', fontStyle: 'italic'}}>
              <th></th>
              <th></th>
              <th>Selected Harvests</th>
              <th>Status</th>
              <th>Projected Harvest</th>
            </tr>
            {order.selectedHarvests.map((harvest) => (
              <tr key={harvest.docID} onClick={() => handleHarvestClick(harvest.docID)} style={{fontSize: '13px', fontStyle: 'italic'}}>
                <td></td>
                <td></td>
                <td style={{backgroundColor: 'lightgreen'}}>{harvest.harvestName}</td>
                <td style={{backgroundColor: 'lightgreen'}}>{harvest.status}</td>
                <td style={{backgroundColor: 'lightgreen'}}>{harvest.projectedHarvestDate ? harvest.projectedHarvestDate.toDate().toLocaleDateString() : 'N/A'}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
        </tbody>
      </table>
    </div>
  );

};

export default Orders;
