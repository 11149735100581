import React, { Component } from "react";
import homepage_image from '../homepage_image.png';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const home_box = {
  paddingLeft: '20px',
}
const homepage_image_style = {
  width: '95%'
}

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if(this.props.isLoggedIn) {
      history.push("/userhome");
      window.location.reload()
    }
  }
  render() {
    return (
      <div style={home_box}>
        <br />
        {/* <div className="title is-5">Welcome to the Plant App</div> */}
        <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            {/* Hero Section */}
            <h1 className="title">The Sprout Report</h1>
            <p className="subtitle">Dial In Your Harvest Process.</p>
            {!this.props.isLoggedIn && (
              <div className="buttons">
                <a href="/signup" className="button is-primary is-rounded">Sign Up</a>
                <a href="/login" className="button is-info is-rounded">Sign In</a>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          {/* Forum Section */}
          {!this.props.isLoggedIn && (<h2 className="title is-3">Join Our Community</h2>)}
          {this.props.isLoggedIn && (<h2 className="title is-3">Community</h2>)}
          <p>Connect with other users, ask questions, and get support.</p>
          <br />
          <a href="/community" className="button is-link">Go to Community</a>
        </div>
      </section>

      {!this.props.isLoggedIn && (
          <section className="section">
            <div className="container">
              {/* Info Section */}
              <h2 className="title is-3">Why Choose Our Software?</h2>
              <ul>
                <li>Feature 1: Track and Manage the Harvest Process</li>
                <li>Feature 2: Analyize and Report on Results for PlantTypes</li>
                <li>Feature 3: Calendar Tool manages Tasks</li>
                <li>Feature 4: CRM Tool manages your customers</li>
              </ul>
              <br />
              <a href="/contact" className="button is-link">Contact Us</a>
            </div>
          </section>
        )}
        {this.props.isLoggedIn && (
          <section className="section">
              <a href="/contact" className="button is-link">Contact Us</a>
          </section>
        )}

<section className="section">

        <h2 className="title is-3">User Home Example</h2>
        <img style={homepage_image_style} src={homepage_image} alt="homepage_image" />
        </section>

      </div>
    );
  }
}
