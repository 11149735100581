import React, { useState }  from "react";

import TemperatureChart from './components/TemperatureChart.js';
import Harvests from './components/Harvests.js';
import AddHarvest from './components/AddHarvest.js';
import Harvest from './components/Harvest.js';
import HarvestCalendar from './components/HarvestCalendar.js';
import PlantTypes from './components/PlantTypes.js';
import Settings from './components/Settings.js';
import Orders from './components/Orders.js';
import Customers from './components/Customers.js';
import AddEditPlantType from './components/AddEditPlantType.js';
import AddEditSettings from './components/AddEditSettings.js';
import AddEditOrder from './components/AddEditOrder.js';
import AddEditCustomer from './components/AddEditCustomer.js';
import LoginPage from './components/LoginPage.js';
import SignUpPage from './components/SignUpPage.js';
import {Navbar} from "./components/Navbar.js"
import Home from "./components/Home.js"
import UserHome from "./components/UserHome.js"
import Community from "./components/Community.js"
import Contact from "./components/Contact.js"
import MasterAdmin from "./components/MasterAdmin.js"


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();


const background = {
  // backgroundColor: 'tan',
  backgroundImage: 'linear-gradient(white, #decfa6)',
  paddingBottom: '1in',
  // paddingRight: '0.25in'
}

function App ()  {
  const isLoggedInlocalStorage = () => {
    // user info can be loaded after refresh
    return !!window.localStorage.getItem('isLoggedIn'); // !! : cast to boolean
  }
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInlocalStorage());

  const handleLogIn = (user) => {
    setIsLoggedIn(true);
    window.localStorage.setItem('isLoggedIn', true);
    history.push("/home");
    window.location.reload()
    console.log(user)
  };

  const handleLogOut = () => {
    setIsLoggedIn(false);
    console.log('handleLogOut')
    window.localStorage.removeItem('isLoggedIn');
    history.push("/home");
    window.location.reload()
  };
    return (
      <div style={background}>
        <Router history={history}>
          <Navbar isLoggedIn={isLoggedIn} handleLogOut={handleLogOut}/>
          <Routes>
            <Route exact path="/login" element={<LoginPage handleLogIn={handleLogIn} isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/signup" element={<SignUpPage handleLogIn={handleLogIn} isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/home" element={<Home isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/userhome" element={<UserHome isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/temp_humidity" element={<TemperatureChart isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/harvests" element={<Harvests isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/harvest" element={<Harvest isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/add_harvest" element={<AddHarvest isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/plantTypes" element={<PlantTypes isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/settings" element={<Settings isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/orders" element={<Orders isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/customers" element={<Customers isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/add_edit_settings" element={<AddEditSettings isLoggedIn={isLoggedIn}/>}/>
            <Route path="/add_edit_settings/:docID?" element={<AddEditSettings isLoggedIn={isLoggedIn} />} />
            <Route exact path="/add_edit_plantTypes" element={<AddEditPlantType isLoggedIn={isLoggedIn}/>}/>
            <Route path="/add_edit_plantTypes/:docID?" element={<AddEditPlantType isLoggedIn={isLoggedIn} />} />
            <Route exact path="/add_edit_order" element={<AddEditOrder isLoggedIn={isLoggedIn}/>}/>
            <Route path="/add_edit_order/:docID?" element={<AddEditOrder isLoggedIn={isLoggedIn} />} />
            <Route exact path="/add_edit_customer" element={<AddEditCustomer isLoggedIn={isLoggedIn}/>}/>
            <Route path="/add_edit_customer/:docID?" element={<AddEditCustomer isLoggedIn={isLoggedIn} />} />
            <Route exact path="/calendar" element={<HarvestCalendar isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/community" element={<Community isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/contact" element={<Contact isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/master_admin" element={<MasterAdmin isLoggedIn={isLoggedIn}/>}/>
            <Route exact path="/" element={<Home isLoggedIn={isLoggedIn}/>}/>
          </Routes>
        </Router>
      </div>
    );
}
export default App;
