import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { useParams } from 'react-router-dom';
import { waitForCurrentUser } from "../js_functions.js";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const settingFormStyle = {
  paddingTop: '30px',
  paddingLeft: '30px'
};
const AddEditSetting = ({ isLoggedIn }) => {
  const { docID } = useParams();
  const [settingName, setSettingName] = useState('');
  const [lightCycle, setLightCycle] = useState(null);
  const [useAsMainSetting, setUseAsMainSetting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    console.log(docID);
    if (docID) {
      console.log(docID);
      setIsEditing(true);
      loadSetting(docID);
    }
  }, [docID]);

  const loadSetting = async (docID) => {
    console.log(docID);
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const settingDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/settings', docID);
      const settingDoc = await getDoc(settingDocRef);
      if (settingDoc.exists()) {
        setSettingName(settingDoc.data().settingName);
        setLightCycle(settingDoc.data().lightCycle);
        setUseAsMainSetting(settingDoc.data().useAsMainSetting);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (useAsMainSetting) {
      // check if another setting is already set as the main setting
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const settingsCollectionRef = collection(getFirestoreDB(), "system", userDoc.data().system, "settings");
      const mainSettingQuery = query(settingsCollectionRef, where("useAsMainSetting", "==", true));
      const mainSettingQuerySnapshot = await getDocs(mainSettingQuery);

      if (mainSettingQuerySnapshot.docs.length > 0) {
        // prompt the user to confirm changing the main setting
        const confirmed = window.confirm("Are you sure you want to switch the main setting? This will turn off the current main setting.");
        if (!confirmed) {
          // if the user cancels the operation, reset the checkbox and return without saving
          setUseAsMainSetting(false);
          return;
        }

        // turn off the existing main setting
        const mainSettingDoc = mainSettingQuerySnapshot.docs[0];
        await updateDoc(mainSettingDoc.ref, { useAsMainSetting: false });
      }
    }

    // update the current setting
    const newSetting = {
      settingName: settingName,
      lightCycle: lightCycle,
      useAsMainSetting: useAsMainSetting,
    };

    const userID = await getFirestoreAuth().currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID);

    try {
      const userDoc = await getDoc(userDocRef);
      const settingsCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "settings");

      if (isEditing) {
        const settingDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + "/settings", docID);
        await updateDoc(settingDocRef, newSetting);
      } else {
        await setDoc(doc(settingsCollection), newSetting);
      }

      // reload the page
      history.push("/settings");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };


  const handleDelete = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const settingDocRef = doc(getFirestoreDB(), "system/" + userDoc.data().system + '/settings', docID);
      await deleteDoc(settingDocRef);
      history.push("/settings");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    history.back(); // Go back to the previous page
  };

  return (
    <div style={settingFormStyle}>

      <div style={{display:'grid', gridTemplateColumns: '1.2in 1.1in 1.1in', gridColumnGap: '0.3in'}}>
        <div className="title is-5">{isEditing ? 'Edit Setting' : 'Add Setting'}</div>
        <button className="button is-success" onClick={handleSubmit}>{isEditing ? 'Save' : 'Add'}</button>
        <button className="button" type="button" onClick={handleCancel}>Cancel</button>
      </div>
      <br />
      <form onSubmit={handleSubmit}>
        <label>Setting Name: <input value={settingName} onChange={(e) => setSettingName(e.target.value)} /></label><br />
        <label>Light Cycle: <input type="number" value={lightCycle ?? ''} onChange={(e) => setLightCycle(e.target.value)} /></label><br />
        <br />
        <div>
          <label htmlFor="useAsMainSetting">Use as main setting:</label>
          <input
            type="checkbox"
            id="useAsMainSetting"
            name="useAsMainSetting"
            checked={useAsMainSetting}
            onChange={(e) => setUseAsMainSetting(e.target.checked)}
          />
        </div>
        <br />
        <br />
        {isEditing && (
          <button className="button is-danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>Delete</button>
        )}
        <br />
        <br />
      </form>
    </div>
  );
};
export default AddEditSetting;
