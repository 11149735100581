import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDks8f65gItnSoMJC3N0Gmew7xwmbnZBak",
  authDomain: "plant-app-202.firebaseapp.com",
  databaseURL: "https://plant-app-202-default-rtdb.firebaseio.com",
  projectId: "plant-app-202",
  storageBucket: "plant-app-202.appspot.com",
  messagingSenderId: "11739990990",
  appId: "1:11739990990:web:b13f36b2f44a4d1755f99f",
  measurementId: "G-GFZES1RYKG"
};
const app = initializeApp(firebaseConfig)


let rtdb = false;
let firestore = false;
let authInstance = false;
let uid = 0

export const getRTDB = () => {
    if(!rtdb)
      rtdb = getDatabase(app)
    return rtdb
}

export const getFirestoreDB = () => {
  if(!firestore)
    firestore = getFirestore(app)
  return firestore
}

export const getFirestoreAuth = () => {
  if(!authInstance)
    authInstance = getAuth(app)
  return authInstance
}

