import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { getFirestoreAuth } from "../firebase";
getFirestoreAuth()


const navbar_title = {
  paddingLeft: '20px',
}

const navbar = {
  // position: 'fixed', // Add this line to position the navbar-start
  paddingLeft: '10px',
  display: 'flex',
  height: '0.9in',
  gap: '20px',
  borderBottom: '1px solid',
  width: '100%', // Add this line to make the navbar full width
}
const navbar_start = {
  // position: 'fixed', // Add this line to position the navbar-start
  top: 0, // Add this line to position the navbar-start below the navbar
  left: 0, // Add this line to position the navbar-start at the left
  zIndex: 1000, // Add this line to set a higher z-index value
  width: '1.5in',
  backgroundColor: 'white',
  paddingTop: '0.15in',
  // paddingLeft: '0.1in'
};
const navbar_grid = {
  display: 'grid',
  gridTemplateColumns: '0.5fr 1fr',
  width: '100%',
}

export const Navbar = props => {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };

  const clickLogOut = () => {
    signOut(getFirestoreAuth())
      .then(() => {
        props.handleLogOut();
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const loggedOutLinks = (
    <>
      <Link to="/home" className="navbar-item">
        Home
      </Link>
    </>
  );

  const loggedInLinks = (
    <div className={`navbar-item has-dropdown ${isActive ? 'is-active' : ''}`} ref={menuRef}>
      <div className="navbar-link title is-5" onClick={toggleMenu}>
        Menu
      </div>
      <div className="navbar-dropdown">
        <Link to="/userhome" className="navbar-item" onClick={toggleMenu}>
          Home
        </Link>
        <Link to="/temp_humidity" className="navbar-item" onClick={toggleMenu}>
          TempHumidity
        </Link>
        <Link to="/customers" className="navbar-item" onClick={toggleMenu}>
          Customers
        </Link>
        <Link to="/harvests" className="navbar-item" onClick={toggleMenu}>
          Harvests
        </Link>
        <Link to="/orders" className="navbar-item" onClick={toggleMenu}>
          Orders
        </Link>
        <Link to="/calendar" className="navbar-item" onClick={toggleMenu}>
          Calendar
        </Link>
        <Link to="/plantTypes" className="navbar-item" onClick={toggleMenu}>
          PlantTypes
        </Link>
        <Link to="/trayBuilder" className="navbar-item" onClick={toggleMenu}>
          Tray Builder
        </Link>
        {/* <Link to="/settings" className="navbar-item" onClick={toggleMenu}>
          Settings
        </Link> */}
        <div onClick={clickLogOut} className="navbar-item">
          Log Out
        </div>
      </div>
    </div>
  );

  return (
    <nav style={navbar}  className="navbar" role="navigation" aria-label="main navigation">
      <div style={navbar_grid} >
        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div style={navbar_start} className="navbar-start">
            {props.isLoggedIn ? loggedInLinks : loggedOutLinks}
          </div>

        </div>
        <div className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <Link to={(props.isLoggedIn ? "/userhome" : "/home")} className="navbar-item" style={{
    backgroundColor: 'lightgreen',
    marginRight: '30px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '8px',
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    transition: 'background-color 0.3s ease-in-out',
  }}>
        Home
      </Link>
    </nav>
  );
};
