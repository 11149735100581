import React from 'react';
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { collection, query, where, getDocs, getDoc, doc, collectionGroup } from "firebase/firestore";
import { waitForCurrentUser } from "../js_functions.js"
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const localizer = momentLocalizer(moment);


const EventTooltip = ({ event }) => (
  <div className="event-tooltip">{event.title}</div>
);

const EventWrapper = ({ event, children }) => (
  <div className="event-wrapper" title={event.title}>
    {children}
  </div>
);

const MultiDayEvent = ({ event }) => {
  const style = {
    backgroundColor: 'blue',
    borderRadius: '0px',
    opacity: 0.8,
    color: 'white',
    border: '0px',
    display: 'block',
    paddingLeft: '2px',
    paddingRight: '2px',
    height: '20px', // set the height as required
  };
  return <div style={style}>{event.title}</div>;
};
const handleEventClick = (event) => {
  history.push("/harvest?id=" + event.id);
  window.location.reload()
};
class HarvestCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      harvests: [],
      events: []
    };
  }
  componentDidMount() {
    if(!this.props.isLoggedIn) {
      history.push("/login");
      window.location.reload()
    }
    this.loadHarvests().then(() => {
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.harvests !== this.state.harvests) {
      this.buildEvents();
    }
  }


  loadHarvests = async () => {
    let currentUser = await waitForCurrentUser();
    let userID = await currentUser.uid;
    const userDocRef = doc(getFirestoreDB(), "users", userID)
    try {
      const userDoc = await getDoc(userDocRef)
      const systemDocs = await getDocs(collection(getFirestoreDB(), "system", userDoc.data().system, 'harvests'));

      const harvests = systemDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      this.setState({ harvests }); // update the state with the loaded harvests



    } catch(error) {
      console.log(error)
    }
  }

  buildEvents = async () => {
    this.state.harvests.forEach(harvest => {

      if (harvest.sowDate) {
        let event = {
          id: harvest.id,
          title: 'SOW: ' + harvest.harvestName,
          start: harvest.sowDate.toDate(),
          end: harvest.sowDate.toDate(),
          estimatedYield: 10 // estimated yield in pounds
        }
        this.setState(prevState => ({
          events: [...prevState.events, event],
        }));
      }
      if (harvest.vegetationStartDate) {
        let event = {
          id: harvest.id,
          title: 'VEG: ' + harvest.harvestName,
          start: harvest.vegetationStartDate.toDate(),
          end: harvest.vegetationStartDate.toDate(),
          estimatedYield: 10 // estimated yield in pounds
        }
        this.setState(prevState => ({
          events: [...prevState.events, event],
        }));
      }
      if (harvest.projectedHarvestDate) {
        let event = {
          id: harvest.id,
          title: 'HARV: ' + harvest.harvestName,
          start: harvest.projectedHarvestDate.toDate(),
          end: harvest.projectedHarvestDate.toDate(),
          estimatedYield: 10 // estimated yield in pounds
        }
        this.setState(prevState => ({
          events: [...prevState.events, event],
        }));
      }
    });
  }

  // const eventStyleGetter = (event, start, end, isSelected) => {
  //   const backgroundColor = '#bada55';
  //   return {
  //     style: {
  //       backgroundColor: 'green',
  //       height: '20px',
  //     }
  //   };
  // };
  render() {
  return (
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={this.state.events}
        startAccessor="start"
        endAccessor="end"
        eventOverlap={false}
        eventWrapperComponent={EventWrapper}
        onSelectEvent={handleEventClick} // Add the onClick handler
        components={{
          eventTooltip: EventTooltip,
          event: MultiDayEvent }}
        // eventPropGetter={eventStyleGetter}
        eventPropGetter={(event, start, end, isSelected) => {
          const backgroundColor = isSelected ? 'green' : 'blue';
          const style = {
            backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
            paddingLeft: '2px',
            paddingRight: '2px',
          };
          return { style };
        }}
        eventStyleGetter={(event, start, end, isSelected) => {
          const backgroundColor = isSelected ? 'blue' : 'red';
          const style = {
            backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
            paddingLeft: '2px',
            paddingRight: '2px',
          };
          return { style };
        }}
        eventPropHooks={{
          // Set the height of multi-day events
          getEventWrapper: (event) => {
            return {
              style: {
                height: '100px', // set the height as required
                display: 'flex',
                alignItems: 'center',
              },
            };
            // if (event.end.getDay() !== event.start.getDay()) {
            //   return {
            //     style: {
            //       height: '120px', // set the height as required
            //       display: 'flex',
            //       alignItems: 'center',
            //     },
            //   };
            // }
            // return {};
          },
        }}
      />
    </div>
  );
      }
};

export default HarvestCalendar;
