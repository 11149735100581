import React, { useState, useEffect } from 'react';
import { collection, getDoc, getDocs, doc, where } from "firebase/firestore";
import { getFirestoreDB, getFirestoreAuth } from "../firebase.js";
import { waitForCurrentUser } from "../js_functions.js";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const tableContainerStyle = {
  overflow: 'auto',
};

const customersPageStyle = {
  paddingTop: '30px',
  paddingLeft: '30px'
};
const tableHeaderStyle = {
  verticalAlign: 'bottom',
};
const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [sortColumn, setSortColumn] = useState(''); // Initially empty
  const [sortOrder, setSortOrder] = useState('asc'); // Initially ascending

    useEffect(() => {
    loadCustomers();
  }, [sortColumn, sortOrder]);

  const loadCustomers = async () => {
    try {
      const currentUser = await waitForCurrentUser();
      const userID = currentUser.uid;
      const userDocRef = doc(getFirestoreDB(), "users", userID);
      const userDoc = await getDoc(userDocRef);
      const customersCollection = collection(getFirestoreDB(), "system", userDoc.data().system, "customers");
      const customersSnapshot = await getDocs(customersCollection);
      let customersList = customersSnapshot.docs.map(doc => ({ docID: doc.id, ...doc.data() }));

      // Fetch orders for each customer and calculate orders in progress
      for (const customer of customersList) {
        const ordersSnapshot = await getDocs(
          collection(getFirestoreDB(), "system", userDoc.data().system, "orders"),
          where("customer", "==", customer.docID),
          where("status", "!=", "closed_out"),
          where("isArchived", "==", false),
          where("display", "==", true)

        );
        const orders = ordersSnapshot.docs.map(doc => doc.data());
        let ordersInProgress = orders.filter(order => order.customer === customer.docID && order.status !== 'closed_out').length;
        customer.ordersInProgress = ordersInProgress;
      }
      // Filter out customers with display=false
      customersList = customersList.filter(customer => customer.display !== false);

      // Sort the customers list based on the selected column and order
      if (sortColumn) {
        customersList.sort((a, b) => {
          const valueA = getNestedValue(a, sortColumn);
          const valueB = getNestedValue(b, sortColumn);

          if (sortColumn === 'customerName') {
            // Case-insensitive sorting for customerName
            const nameA = String(valueA).toLowerCase();
            const nameB = String(valueB).toLowerCase();
            return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
          } else if (sortColumn === 'ordersInProgress') {
            // Numeric comparison for ordersInProgress column
            return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
          } else {
            // Default string comparison for other columns
            return sortOrder === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
          }
        });
      } else {
        // Default sorting behavior when sortColumn is empty
        customersList.sort((a, b) => {
          const nameA = String(a.customerName).toLowerCase();
          const nameB = String(b.customerName).toLowerCase();
          return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });
      }
      setCustomers(customersList);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddClick = () => {
    history.push("/add_edit_customer");
    window.location.reload();
  };
  const handleEditClick = (docID) => {
    history.push(`/add_edit_customer/${docID}`);
    window.location.reload();
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };
  // Helper function to get the nested value from an object based on the given path
  const getNestedValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        value = null;
        break;
      }
    }
    return value;
  };
  const SortIndicator = ({ sortOrder }) => {
    if (sortOrder === 'asc') {
      return <span>&#9650;</span>; // Up arrow
    } else {
      return <span>&#9660;</span>; // Down arrow
    }
  };
  return (
    <div style={customersPageStyle}>
      <div className="title is-5">Customers</div>
      <button className="button" onClick={handleAddClick}>Add Customer</button>
      <br />
      <br />
      <div style={tableContainerStyle}>
        <table className="table">
          <thead>
            <tr>
              <th onClick={() => handleSort('customerName')} style={tableHeaderStyle}>
                Customer Name {sortColumn === 'customerName' && <SortIndicator sortOrder={sortOrder} />}
              </th>
              <th onClick={() => handleSort('ordersInProgress')} style={tableHeaderStyle}>
                Orders in<br/>Progress {sortColumn === 'ordersInProgress' && <SortIndicator sortOrder={sortOrder} />}
              </th>
              <th onClick={() => handleSort('mailingAddress.address1')} style={tableHeaderStyle}>
                Address {sortColumn === 'mailingAddress.address1' && <SortIndicator sortOrder={sortOrder} />}
              </th>
              <th onClick={() => handleSort('mailingAddress.city')} style={tableHeaderStyle}>
                City {sortColumn === 'mailingAddress.city' && <SortIndicator sortOrder={sortOrder} />}
              </th>
              <th onClick={() => handleSort('mailingAddress.state')} style={tableHeaderStyle}>
                State {sortColumn === 'mailingAddress.state' && <SortIndicator sortOrder={sortOrder} />}
              </th>
              <th onClick={() => handleSort('mailingAddress.zip')} style={tableHeaderStyle}>
                Zip {sortColumn === 'mailingAddress.zip' && <SortIndicator sortOrder={sortOrder} />}
              </th>
            </tr>
          </thead>
          <tbody>
            {customers.map(customer => (
              <tr key={customer.docID} onClick={() => handleEditClick(customer.docID)} style={{cursor: 'pointer'}}>
                <td>{customer.customerName}</td>
                <td>{customer.ordersInProgress}</td>
                <td>{customer.mailingAddress?.address1}</td>
                <td>{customer.mailingAddress?.city}</td>
                <td>{customer.mailingAddress?.state}</td>
                <td>{customer.mailingAddress?.zip}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customers;
