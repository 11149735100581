import React from 'react';
import { doc, getDoc } from "firebase/firestore";
import { getFirestoreAuth, getFirestoreDB } from "../firebase.js";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

class MasterAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMasterAdmin: false, // Initialize a state variable to track if the user is a master admin
      authChecked: false, // Add a flag to track when authentication state has been checked
    };
  }

  componentDidMount() {
    // Get the Firebase Authentication object
    const auth = getFirestoreAuth();

    // Listen for changes in the authentication state
    auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid; // Get the UID of the current user
        const userDocRef = doc(getFirestoreDB(), "users", uid); // Replace "users" with the name of your collection

        // Fetch the user document from Firestore
        getDoc(userDocRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              const isMasterAdmin = userData.master_admin === true; // Check if master_admin is set to true

              console.log(isMasterAdmin);

              this.setState({ isMasterAdmin, authChecked: true });
              if (!isMasterAdmin) {
                history.push("/home");
                window.location.reload();
              }
            } else {
              console.log("User document does not exist.");
              // Handle the case where the user document does not exist
              this.setState({ authChecked: true }); // Authentication state checked even if user document doesn't exist
            }
          })
          .catch((error) => {
            console.error("Error getting user document:", error);
            // Handle any errors that occur during the fetch
            this.setState({ authChecked: true }); // Authentication state checked even in case of an error
          });
      } else {
        // Handle the case where there is no authenticated user (user not logged in)
        this.setState({ authChecked: true }); // Authentication state checked even if no user is logged in
      }
    });
  }

  componentWillUnmount() {
    // Code to run before the component unmounts
  }

  render() {
    const { isMasterAdmin, authChecked } = this.state;

    // Only render content when the authentication state has been checked
    if (!authChecked) {
      return null; // You can render a loading spinner or other content while waiting for authentication
    }

    console.log(this.state);

    return (
      <div>
        <h1>Welcome to the Master Admin Panel</h1>
        {isMasterAdmin ? (
          // Render content for master admins
          <div>
            <p>You are a master admin.</p>
            {/* Add your component content for master admins here */}
          </div>
        ) : (
          // Render content for non-master admins
          <div>
            <p>You do not have master admin privileges.</p>
            {/* Add your component content for non-master admins here */}
          </div>
        )}
      </div>
    );
  }
}

export default MasterAdmin;
